<template lang="pug">
  include /mixins
  tag(
    :tag='country_config.tag'
    v-model='country'
    :input-label='country_config.title',
    :rules='country_config.rules'
    v-bind='[country_config.props, props]'
    v-on='country_config.events'
    :name='country_config.name'
  )
    template(#option="{ option }")
      +b.switcher-with-dropdown
        +e.current--dark
          +e.img-wrap
            +e.img.IMG(
              :src='option.props && option.props.icon'
            )
          +e.link--dark.ds-caption--size_sm.--appearance_uppercase.SPAN {{ option.props && option.props.title }}
    template(#singleLabel="{ option }")
      +b.switcher-with-dropdown
        +e.current--dark
          +e.img-wrap
            +e.img.IMG(
              :src='option.props && option.props.icon'
            )
          +e.link--dark.ds-caption--size_sm.--appearance_uppercase.SPAN {{ option.props && option.props.title }}
</template>


<script>

import {
  countriesListResource,
} from '@api/vendor.service'

import pathOr from 'ramda/src/pathOr'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

export default {
  name: 'CountryInput',
  countriesListResource,
  props: {
    props: {
      type: Object,
      default: () => {},
    },
    value: {},
    hasInit: {
      default: true,
    },
  },
  data() {
    return {
      country: {},
      phone: '',
      code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      default_code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      list: [],
      regexp: [],
      country_config: {
        size: '12',
        title: '',
        name: 'country',
        tag: 'd-control-multiselect',
        events: {
        //   open: $event => this.getCities($event, 'new_post_courier', 'country'),
        //   'search-change': $event => this.getCities($event, 'new_post_courier', 'country'),
          input: () => this.save(),
        },
        // afterList: visible => { this.getCities('', 'new_post_courier', 'country', true) },
        props: {
          pagination: {},
          options: [],
          label: 'caption',
          customLabel: ({ props }) => props?.title,
          searchable: true,
          preserveSearch: true,
          trackBy: 'id',
          hideSelected: true,
        },
      },
    }
  },
  created() {
    this.getCountries()
  },
  watch: {
    value: {
      async handler() {
        this.country = this.value
        // if (!this.list.length) await this.getPhoneCodes()
        // this.phone = this.value;
        // this.checkCode()
      },
      immediate: true,
    },
  },
  methods: {
    async getCountries() {
      const { data } = (await countriesListResource.execute())
      this.country_config.props.options = data.reduce((acc, obj) => {
        const parent = pathOr(null, ['props', 'parent', 'props', 'id'], obj)
        const p = data.find(e => e.id === parent)
        if (p) {
          p.props.children = p.props.children || []
          p.props.children.push(obj)
        }
        if (obj.props.parent) return acc
        acc.push(obj)
        return acc
      }, [])
      if (this.hasInit) {
        this.country = this.country_config.props.options.find(e => e.props.iso_code === this.code)
        if (!this.country) {
          this.country = this.country_config.props.options.find(e => e.props.children && e.props.children.find(r => r.props.iso_code === this.code))
        }
        this.save()
      }
    },
    save() {
      this.$emit('input', this.country)
    },
  },

}
</script>
