import CartPreview from '@app/Cart/Preview'
import GiftVariants from '@app/Cart/GiftVariants'
import StockChecker from '@app/Cart/StockChecker'
import Promocode from '@app/Cart/Promocode'
import ProductBaseView from '@app/Cart/Variants/Base'
import CartCampaignUi from '@app/Cart/CampaignUi'

export default function install(Vue) {
  Vue.component(CartPreview.name, CartPreview)
  Vue.component(GiftVariants.name, GiftVariants)
  Vue.component(StockChecker.name, StockChecker)
  Vue.component(Promocode.name, Promocode)
  Vue.component(ProductBaseView.name, ProductBaseView)
  Vue.component(CartCampaignUi.name, CartCampaignUi)
}
