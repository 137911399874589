import {
  createResource,
} from '@resource/resource'

import { prefixAPI } from '@resource/api'

const MODEL = 'loyalty-program/'

const VALUES_LIST_URL = prefixAPI('values/list/', MODEL)
const CONDITIONS_LIST_URL = prefixAPI('conditions/list/', MODEL)
const PACKAGES_LIST_URL = prefixAPI('packages/list/', MODEL)
const BRANDS_LIST_URL = prefixAPI('brands/', MODEL)
const DISCOUNT_PACKAGES_LIST_URL = prefixAPI('discount-packages/', MODEL)
const PRODUCTS_LIST_URL = prefixAPI('products/', MODEL)

export const valuesListResponse = createResource(VALUES_LIST_URL)
export const conditionsListResponse = createResource(CONDITIONS_LIST_URL)
export const packagesListResponse = createResource(PACKAGES_LIST_URL)
export const brandsListResponse = createResource(BRANDS_LIST_URL)
export const discountPackagesListResponse = createResource(DISCOUNT_PACKAGES_LIST_URL)
export const productsListResponse = createResource(PRODUCTS_LIST_URL)
