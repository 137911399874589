<template lang="pug">
  include /mixins
  +b.g-row--align_center.--justify_between
    +b.g-cell.g-cols--12.--6-md.--4-xl(
      v-for='field in config'
    )
      tag(
        :tag='field.tag'
        v-bind='field.props'
        v-model='form.filters[field.name]'
        v-on='field.events'
        :name='field.name'
        :input-label='field.title',
        :label="field.props.label"
        :track-by="field.props.trackBy"
        :config="field.props.config"
        :append-icon="field.appendIcon || 'none'"
        @input='updateMultipleValues()'
        @clear="form.filters[field] = ''; updateMultipleValues()"
      )
    slot
    //- cell(cols="narrow")
    //-   +b.tt-link--blue.ds-caption--size_2xs.tt-space_mb--5.A(href='javascript.void(0)' @click.prevent="clearFilters") {{_('Очистить')}}
</template>

<script>
import { isEmpty } from 'ramda'

export default {
  props: ['changeFilters', 'filters', 'receive', 'ordersUuids'],
  name: 'AddressBookSidebar',
  data() {
    return {
      form: {
        filters: {
          country_available: '',
        },
      },
      config: [
        // {
        //   title: this._('Страна заказа'),
        //   value: '',
        //   tag: 'country-input',
        //   name: 'country_available',
        //   props: {
        //     options: [],
        //   },
        // },
      ],
      // options: {
      //   payment_status: [],
      //   status: [],
      // },
      res: {},
    }
  },
  // mounted() {
  //   this.getPaymentStatuses()
  //   this.getOrderStatus()
  // },
  methods: {
    updateMultipleValues() {
      this.res = { filters: {} }
      this.res.filters.country_available = this.form.filters.country_available?.props?.iso_code || ''
      Object.keys(this.res.filters).forEach(k => {
        if (isEmpty(this.res.filters[k])) delete this.res.filters[k]
        if (!Array.isArray(this.res.filters[k])) [this.res.filters[k]]
      })

      // this.changeFilters(this.res)
      this.changeFilters(this.res)
      this.$emit('update', this.res)
    },
    clearFilters() {
      Object.keys(this.res.filters).forEach(key => {
        delete this.res.filters[key]
        this.form.filters[key] = ''
      })
      this.changeFilters(this.res.filters)
      this.$emit('update', this.res)
    },
  },
}
</script>
