<template lang="pug">
  include /mixins
  tag(
    :tag="inOrder ? 'div' : 'user-address-book'"
  )
    paginated-resource-controller(
      :changeParameters="changeParameters"
      ref='controller'
      :parameters="parameters"
      v-slot="{ pagination, changePagination, changeFilters, filters, receive, result, loading, parameters }"
      :resource="$options.addressBookList"
      :immediate='false'
    )
      div.relative
        ui-loader(v-if='loading')
        template(
          v-if='!inOrder'
        )
          +b.tt-space_mt--5
            address-book-sidebar(
              :changeFilters='$event => getAndSet(changeFilters, $event)'
              :filters='filters'
              :receive='receive'
              @update='updateParams($event)'
            )
              +b.g-cell.g-cols--12.--6-md.--4-xl
                +b.tt-button--redesign-h.--free.ROUTER-LINK(
                  :to='{ name: $options.ADDRESS_BOOK_ADD_NAME }'
                  v-if='result && result.items'
                )
                  +e.title.P {{ _(' добавить новый адрес') }}
            +b.tt-space_mt--5
            +b.line-h--light
        template(v-else)
          +b.ds-caption--size_sm.--medium.P(
            v-if='result && result.items'
          ) {{ _('Выберите адрес из списка или') }} &nbsp;
            +b.tt-link--styling_default.--appearance_inline.ds-caption--size_sm.A(
              @click.prevent='addAddress(changeFilters)'
            ) {{ _('добавьте новый') }}
        template(
          v-if='result && result.items && result.items.length'
        )
          v-selection-controller(
            :options='result.items'
            :multiple='false',
            :key-getter="$options.keyGetter",
            v-model='address'
            v-if='inOrder'
            v-slot="{ options, value: model, change }"
          )
            div.address-snap-wrap
              div.address-snap-element(v-for='{ value }, idx in options')
                +b.d-control-input.order-address-card-wrapper(
                  :class="{'is-single': result && result.items && result.items.length == 1}"
                )
                  template(v-if="!value.props.not_available_text")
                    +e.element.INPUT.order-address-card-input(
                      type='radio'
                      name='address'
                      :value='value'
                      :checked='address == value'
                      :ref='`address`'
                      @change='change(value); $emit("update"); selectAddress(value)'
                      :input-label='value.title',
                      :id='`address:${value.id}`'
                      :key='`address:${value.id}`'
                    )
                    +e.LABEL.label--static.order-address-card-label(
                      :for='`address:${value.id}`'
                    )
                      +b.order-address-select.P {{ address == value ? _('Выбрано') : _('Выбрать') }}
                      tag(
                        :tag='"order-address-card"'
                        :item='value',
                        :key='value.id'
                        :current='address'
                        @update:list='receiveAndCheck(changeFilters, idx, result.items)'
                      )
                      +b.tt-space_mt--2.ROW(
                        align='center'
                        justify='start'
                        v-if='address == value && isFree && freeShippingMessage'
                      )
                        cell(cols)
                          row
                            cell(cols)
                              +b.ds-caption--color_accent.--size_sm.P
                                span.icon-shipping-car
                            cell(cols)
                              +b.ds-caption--size_sm.--color_accent.P {{ _('Доставка бесплатно') }}
                      +b.tt-space_mt--2.ROW(
                        align='center'
                        justify='start'
                        v-if='address == value && !isFree && shippingPrice'
                      )
                        cell(cols)
                          row
                            cell(cols)
                              +b.ds-caption--color_accent.--size_md.P
                                span.icon-shipping-box
                            cell(cols)
                              +b.ds-caption--size_sm.--color_accent.P {{ _('Стоимость доставки:') }} #[span(v-html='getSplittedHtml(shippingPrice)')]
                            cell(cols='12')
                              +b.ds-caption--size_xs.--light.P {{ deliveryDescription }}
                      +b.ds-caption--size_xs.--color_red.P(
                        v-if='!isFullyAddress(value)'
                      ) {{ _('В адресе доставки заполнены не все обязательные данные') }}
                  template(v-else)
                    +b.ds-caption--color_red.--size_xs.P() {{ value.props.not_available_text }}
                    tag(
                      :tag='"order-address-card"'
                      :item='value',
                      :key='value.id'
                      :current='address'
                      @update:list='receiveAndCheck(changeFilters, idx, result.items)'
                    )
                    +b.tt-space_mt--2.ROW(
                      align='center'
                      justify='start'
                      v-if='address == value && isFree && freeShippingMessage'
                    )
                      cell(cols)
                        row
                          cell(cols)
                            +b.ds-caption--color_accent.--size_sm.P
                              span.icon-shipping-car
                          cell(cols)
                            +b.ds-caption--size_sm.--color_accent.P {{ _('Доставка бесплатно') }}
                    +b.tt-space_mt--2.ROW(
                      align='center'
                      justify='start'
                      v-if='address == value && !isFree && shippingPrice'
                    )
                      cell(cols)
                        row
                          cell(cols)
                            +b.ds-caption--color_accent.--size_md.P
                              span.icon-shipping-box
                          cell(cols)
                            +b.ds-caption--size_sm.--color_accent.P {{ _('Стоимость доставки:') }} #[span(v-html='getSplittedHtml(shippingPrice)')]
                          cell(cols='12')
                            +b.ds-caption--size_xs.--light.P {{ deliveryDescription }}
                +b.line-h--light
          tag(
            :tag='"address-card"'
            v-for='card in result.items'
            :item='card',
            v-else
            :key='card.id'
            @update:list='changeFilters(parameters)'
          )
        template(v-if='!(result && result.items && result.items.length) && !inOrder')
          +b.ds-caption--size_xsm.--size_sm-xl.--color_grey.P.tt-space_mt--4 {{ _('В вашей адресной книге еще нет адресов доставки.') }}
          +b.ds-caption--size_xsm.--size_sm-xl.--color_grey.P.tt-space_mt--2 {{ _('Вы можете добавить новый для быстрого оформления заказа.') }}
        ui-limit-pagination(
          :pagination='pagination'
          :need-add-btns='false'
          @showAll='handlePaginate($event, changePagination, pagination)'
          @input='handlePaginate($event, changePagination, pagination)'
          @hook:created='getInitial(changeFilters)'
        )
</template>

<script>

import { mapState, mapActions } from 'vuex'

import {
  addressBookList,
} from '@api/staff/address.service'

import {
  getShipping,
} from '@api/order.service'

import {
  ADDRESS_BOOK_ADD_NAME,
} from '@app/Staff/AddressBook/routes'

import AddressCard from '@app/Staff/AddressBook/Card'
import AddressBookSidebar from '@app/Staff/AddressBook/Sidebar'
import OrderAddressCard from '@app/Staff/AddressBook/OrderCard'

import ModalWrapper from '@app/Forms/ModalWrapper'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'
import {
  getCookie,
} from '@utils/cookies'

const CURRENCY = window.currency
const ADDRESS_BOOK_PAGE_LIMIT = 10

export default {
  name: 'UserAddressBookList',
  props: {
    inOrder: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
    isFree: {
      type: Boolean,
      default: false,
    },
    shippingPrice: {},
    deliveryDescription: {},
  },
  components: {
    AddressCard,
    OrderAddressCard,
    AddressBookSidebar,
  },
  keyGetter: x => `${x.type}:${x.id}`,
  addressBookList,
  ADDRESS_BOOK_ADD_NAME,
  CURRENCY,
  data() {
    return {
      parameters: {
        // country_available: getCookie(COUNTRY_CODE_COOKIE_NAME),
        // filters: {
        //   country_available: getCookie(COUNTRY_CODE_COOKIE_NAME),
        // },
        ...this.params,
        limit: ADDRESS_BOOK_PAGE_LIMIT,
      },
      address: '',
      shipping: [],
    }
  },
  computed: {
    ...mapState('staff', [
      'user', 'level',
    ]),
    freeShippingMessage() {
      return window.authent ? this.level?.props?.free_shipping_information : window.free_shipping_information_for_anonim
    },
  },
  methods: {
    selectAddress(address) {
      this.$emit('address:select', { ...address, is_full: this.isFullyAddress(address) })
    },
    isFullyAddress(item) {
      const service = item.props.shipping_service.props.conf_fields
      const required = service.filter(it => it.props.is_required).map(it => it.props.field.props.label)
      const t = required.every(it => item && item.props && item.props[it])
      return t
    },
    updateParams(params) {
      this.parameters = { ...this.parameters, ...params }
      // if (this.parameters.filters && !this.parameters.filters.country_available || this.parameters.filters) {
      //   this.parameters.country_available = getCookie(COUNTRY_CODE_COOKIE_NAME)
      //   this.parameters.filters.country_available = getCookie(COUNTRY_CODE_COOKIE_NAME)
      // }
      console.log(this.parameters, '>>>');
    },
    getInitial(fn) {
      this.getAndSet(fn, this.parameters)
    },
    getAndSet(fn, params) {
      console.log(params, '=========1');
      // if (!params.country_available) {
      //   params.country_available = getCookie(COUNTRY_CODE_COOKIE_NAME)
      // }
      // console.log(params, '=========2');
      fn(params)
        .then(data => {
          if (!data) return
          console.log(data);
          const { items } = data
          const address = items.find(e => !e.props.not_available_text)
          if (address) {
            this.address = address
            this.selectAddress(address)
          }
        })
    },
    handlePaginate({ offset, limit }, fn) {
      if (this.loading) {
        return
      }
      fn({ limit, offset })
    },
    toPromise(arg) {
      if (!arg) return Promise.reject()

      return Promise.resolve(arg)
    },
    addAddress(fn) {
      const pr = f => this.toPromise(f)
        .then(() => {
          this.receiveAndCheck(fn, 0)
        })
      this.$modal.show(
        ModalWrapper,
        {
          classes: ['cart-modal'],
          info: {
            tag: 'AddressBookAdd',
            fromOrderPage: true,
            promise: pr,
          },
        }, {
          classes: ['cart-modal'],
          height: 'auto',
          minWidth: 300,
          adaptive: true,
          scrollable: true,
        }
      )
    },
    changeParameters() {},
    async receiveAndCheck(fn, idx) {
      fn(this.parameters)
        .then(({ items }) => {
          this.address = items[idx]
          this.selectAddress(items[idx])
        })
    },
  },
}
</script>
