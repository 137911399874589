<template lang="pug">
  include /mixins
  div
    //- product-base-view(
    //-   v-bind='productProps'
    //-   v-on='productListeners'
    //- )
    product-sale-view(
      v-bind='productProps'
      v-on='productListeners'
    )

</template>
<script>
import ProductBaseView from './Variants/Base'
import ProductSaleView from './Variants/Sale'

export default {
  name: 'GiftsUi',
  components: {
    ProductBaseView,
    ProductSaleView,
  },
  props: {
    product: {
      default: () => { },
    },
    load: {},
  },

  computed: {
    productListeners() {
      return {
        ...this.$listeners,
        removeFromCart: () => this.$emit('removeFromCart'),
        updateCart: event => this.$emit('updateCart', event),
      }
    },
    productProps() {
      // const { relations, base, price, id } = this.product
      return {
        load: this.load,
        // product: base,
        // group_id: id,
        relations: this.product,
        // price,
        disabled: this.$attrs.disabled,
      }
    },
  },
}
</script>
