import pathOr from 'ramda/src/pathOr'

import {
  countriesListResource,
  countryChangeResource,
  LangChangeResource,
} from '@api/vendor.service'

import {
  removeLanguage,
  prefixLanguage,
} from '@utils/urls'

import {
  getCookie, setCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

import {
  TOTIS_2_NEW_COUNTRY_SELECTED,
} from '@/app.js'

const SET_COUNTRY = 'SET_COUNTRY'
const SET_LIST = 'SET_LIST'

export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      iso_code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      selectedCountry: {},
      originCountriesList: [],
      countriesList: [],
    },
    actions: {
      async getCountries({ commit, state, dispatch }) {
        const { data } = (await countriesListResource.execute())
        commit(SET_LIST, data)
        const country = data.find(c => c.props.iso_code === state.iso_code)
        dispatch('saveCountry', country)
        return country
      },
      saveCountry({ commit }, payload) {
        commit(SET_COUNTRY, payload)
      },
      async changeCountry({ commit, dispatch, state }, { action }) {
        const { data: { lang } } = (await countryChangeResource.execute({ code: state.selectedCountry.props.iso_code }))
        action()
        const url = removeLanguage(window.location.pathname)
        const newUrl = prefixLanguage(url, { language: lang })
        await LangChangeResource.execute({ code: lang })
        setCookie(TOTIS_2_NEW_COUNTRY_SELECTED, '', 0)
        window.location.href = newUrl
      },
    },
    mutations: {
      [SET_COUNTRY](state, payload) {
        state.selectedCountry = payload
      },
      [SET_LIST](state, payload) {
        state.countriesList = payload.reduce((acc, obj) => {
          const parent = pathOr(null, ['props', 'parent', 'props', 'id'], obj)
          const p = payload.find(e => e.id === parent)
          if (p) {
            p.props.children = p.props.children || []
            p.props.children.push(obj)
          }
          if (obj.props.parent) return acc
          acc.push(obj)
          return acc
        }, [])
        state.originCountriesList = payload
      },
    },
  }
}
