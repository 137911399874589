<template lang="pug">
include /mixins
+b.div
  +b.remove-from-cart.SPAN.mdi.mdi-close(
    @click='$emit("removeFromCart")'
  )
  +b.g-row--align_center
    +b.g-cell.g-cols--3.--2-xl.relative
      +b.tt-picture--size_auto.--size_auto-h.product__img--mini-variant.PICTURE.relative
        source(
          type="image/webp"
          :srcset="complectInfo && complectInfo.complect_img.preview_webp"
        )
        +e.body.IMG(
          v-if='complectInfo && complectInfo.complect_img && complectInfo.complect_img.preview'
          :src='complectInfo.complect_img.preview',
        )
        +e.body.IMG(
          v-else
          src='https://via.placeholder.com/60x60',
        )
      +b.cart-label-wrap
        +b.cart-label--static.is_sale(
        )
          +e.title.P {{ _('акция') }}
    +b.g-cell.g-cols--9.--10-xl
      +b.ds-caption--size_sm.--size_sm-xl.--bold.A.tt-link--variant_start(
        v-if='complectInfo && complectInfo.url'
        :href='complectInfo.url'
      ) {{ campaignInfo.campaign_title }}
      +b.ds-caption--size_2xs.--regular.--grey.P {{ setLength }}  {{ $tc('товар', setLength ) }}
      modal-trigger(
        name='SetView',
        :set='set',
        :campaign="campaignInfo"
        url='Cart/SetView',
        :classes='["cart-modal"]'
      )
        template(#default='{ open }')
          +b.tt-link--styling_default.--accent.ds-caption--size_2xs.SPAN(
            @click.prevent='open'
          ) {{ _('посмотреть подробнее') }}
      //- +b.g-row--appearance_column.--justify_between.full-h
      //-   +b.g-cell.g-cols--12
      //-     +b.g-row.--justify_between.full-h
      //-       +b.g-cell.g-cols--12.--6-xl
      //-       //- +b.g-cell.g-cols--6.--3-xl
      //-       //-   +b.ds-caption--size_2xs.--light.--appearance_right.P {{ _('Артикул:') }} #[+b.ds-caption--medium.SPAN {{ _('Акция') }} ]
      //-       +b.g-cell.g-cols--6.--3-xl
      //-   +b.g-cell.g-cols--12
      //-   +b.g-cell.g-cols--12
    +b.g-cell.g-cols--12
  +b.g-row--align_end
    +b.g-cell.g-cols--3.--2-xl.relative
    +b.g-cell.g-cols--7
      .is-visible-xl
        counter(
          :amount='campaignInfo.complect_count',
          @change='$emit("updateCart", { info: $event, campaign: campaignInfo, set })'
          :max-count='maxCount'
          :show-error='maxCount < campaignInfo.complect_count'
        )
        +b.ds-caption--size_2xs.--regular.--color_red(
          v-if='maxCount < campaignInfo.complect_count'
        ) {{ _('Товара нет в необходимом Вам количестве, доступно ') }} {{ maxCount }}
    +b.g-cell.g-cols--12.--3-xl
      +b.tt-space_mt--2.--0-xl
      row(
        justify='end'
        align='center'
      )
        cell(
          cols='6 12-xl'
        )
          .is-disable-xl
            counter(
              :amount='campaignInfo.complect_count',
              @change='$emit("updateCart", { info: $event, campaign: campaignInfo, set })'
              :max-count='maxCount'
              :show-error='maxCount < campaignInfo.complect_count'
            )
            +b.ds-caption--size_2xs.--regular.--color_red(
              v-if='maxCount < campaignInfo.complect_count'
            ) {{ _('Товара нет в необходимом Вам количестве, доступно ') }} {{ maxCount }}
        cell(
          cols='6 12-xl'
        )
          +b.ds-caption--size_sm.--medium.--color_red.--appearance_right.P(
            v-if='campaignInfo.complect_discount_percent'
          ) {{ _('Скидка') }} -{{ campaignInfo.complect_discount_percent }}%
          +b.ds-caption--size_sm.--light.--appearance_right.P  {{ _('Сумма:') }}
        cell(
          cols='12-xl'
        )
          row(align='center' justify='end')
            template(v-if='campaignInfo.price_with_discount')
              cell(cols)
                +b.ds-caption--size_sm.--semibold.--grey.--appearance_right.--appearance_nowrap.--appearance_line-througt.--color_semigrey.SPAN(
                  v-html='getSplittedHtml(campaignInfo.price_without_campaign)'
                )
              cell(cols )
                +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_nowrap.SPAN.tt-space_ml--1(
                  v-html='getSplittedHtml(campaignInfo.price_with_discount)'
                )
            cell(cols v-else)
              +b.ds-caption--size_sm.--semibold.--appearance_right.P(
                v-html='getSplittedHtml(campaignInfo.price_without_campaign)'
              )

</template>

<script>
import { mapState } from 'vuex'

const SET_PREVIEWS_COUNT = 8

export default {
  name: 'CartSetPreview',
  props: ['set', 'id'],
  data() {
    return {
      currency: window.currency,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart',
    ]),
    campaignInfo() {
      const [, id, number] = this.id.split('_')
      return this.cart.parameters.complects_info
        .find(i => i.campaign_id === Number(id) && i.number_complect === Number(number))
    },
    maxCount() {
      return Math.min(...this.set.map(i => Math.floor(i.base.element.props.stock / i.base.parameters.complect_coefficient)))
    },
    setPreviews() {
      const s = JSON.parse(JSON.stringify(this.set.filter(e => e.base.element.props.product)))
      return s.splice(0, SET_PREVIEWS_COUNT)
    },
    firstSet() {
      return this.set[0]
    },
    complectInfo() {
      return this.firstSet.relations[0]?.element?.props?.campaign?.props || this.cart.gifts[this.id][0]?.base?.element?.props?.campaign?.props
    },
    setLength() {
      return this.set.reduce((acc, obj) => acc + obj.base.quantity, 0)
    },
  },
}
</script>
