<template lang="pug">
  include /mixins
  div(
    v-if='order.shipping_service'

  )
    +b.tt-space_mt--6.D-CONTROL-CALENDAR(
      v-model="form.delivery_date"
      @input="handleInput"
      :config="config"
      @on-day-create="onDayCreate"
      :events='eventsToBeEmitted'
      :input-label='_("Желаемая дата доставки")'
    )
    +b.ds-caption--size_xs.--color_red.P(
      v-if='isBonusesAvailable && order.shipping_service.props && form.is_urgent'
    ) {{ order.shipping_service.props.urgent_delivery_description }}
    validation-provider(
      :rules='{ required: timesForSelect.length > $options.DATES_LENGTH }',
      v-slot="{ errors }"
      v-model='selected'
      name='times'
    )
      div.relative
        +b.error-label--top.LABEL(v-if="errors.length") {{ _('Виберите желаемое время доставки') }}
        //- pre {{selected}}
        input(type='hidden', name='times', v-model='selected')
        +b.delivery-time(
          v-if='timesForSelect.length > $options.DATES_LENGTH'
        )
          template(
            v-for='item in timesForSelect'
          )
            +e.item.A(
              href=''
              :class='{ "is-active": selected == item }'
              @click.prevent='select(item)'
            ) {{ _('время с') }} {{ item.after }} {{ _('время до') }} {{ item.before }}
</template>

<script>

import { add, isAfter, lightFormat, differenceInMinutes, isThisSecond } from 'date-fns'

import { getSchedule } from '@api/order.service'

const DAYS_OFFSET = 2

const DATE_WITH_OFFSET = add(new Date(), {
  days: DAYS_OFFSET,
})

const ALT_FORMAT = 'D d.m'

const DATES_LENGTH = 1
const DATES_SELECTED_LENGTH = 1

const DAY_OF_WEEK = {
  0: 'Sunday',
  1: 'monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export default {
  name: 'DateTime',
  props: ['order', 'isBonusesAvailable'],
  DATES_LENGTH,
  data() {
    return {
      form: {
        delivery_date: DATE_WITH_OFFSET,
        is_urgent: false,
        customer_delivery_time_from: '',
        customer_delivery_time_to: '',
      },
      config: {
        // dateFormat: 'd.m.Y',
        altInput: true,
        altFormat: ALT_FORMAT,
        // minDate: DATE_WITH_OFFSET,
        disableMobile: true,
        enable: [],
      },
      selected: null,
      info: {},
      datesConfig: {},
      eventsToBeEmitted: ['onDayCreate'],
    }
  },
  watch: {
    'order.shipping_service.props': {
      handler(nval, oval) {
        if (nval === oval || !this.order.shipping_service?.props) return
        // this.setMinDate()
        this.getSchedule()
      },
      deep: true,
      immediate: true,
    },
    // 'order.city.props': {
    //   handler(nval, oval) {
    //     if (nval === oval || !this.order.city?.props) return
    //     // this.setMinDate()
    //     this.getSchedule()
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    selected: {
      handler() {
        if (!this.order.shipping_service?.props) return
        const d = this.selected?.after ? `${this.selected.after}-${this.selected.before}` : ''
        this.config.altFormat = `${ALT_FORMAT} ${d}`
        this.form.customer_delivery_time_from = this.selected?.after
        this.form.customer_delivery_time_to = this.selected?.before
        this.handleInput()
      },
      deep: true,
      immediate: true,
    },
    'form.delivery_date': {
      handler() {
        if (!this.order.shipping_service?.props) return
        this.setUrgent()
      },
      immediate: true,
    },
    timesForSelect: {
      handler() {
        if (this.timesForSelect.length === DATES_SELECTED_LENGTH) {
          const [time] = this.timesForSelect
          this.selected = time
        }
      },
      deep: true,
    },
    refactorDates: {
      handler() {
        // const dates = this.refactorDates
        // this.selected = time
      },
      deep: true,
    },
  },
  computed: {
    timesForSelect() {
      if (!this.order.shipping_service?.props) return []
      const config = this.getDateConfig(this.form.delivery_date)
      if (!config) return []
      return config.available_time
      const times = this.order.shipping_service.props.delivery_time.filter(e => {
        const [h, m] = e.before.split(':')
        const d = new Date(new Date(this.form.delivery_date).setHours(h)).setMinutes(m)
        const d1 = lightFormat(d, 'yyyy-MM-dd HH:mm')
        const dWith = new Date()
        let d2 = add(dWith, {
          hours: this.order.shipping_service.props.hours_to_delivery_date,
        })
        d2 = lightFormat(d2, 'yyyy-MM-dd HH:mm')
        return isAfter(new Date(d1), new Date(d2))
      })
      return times
    },
    refactorDates() {
      const disable = []
      if (!this.order.shipping_service) return {}
      const { props } = this.order.shipping_service
      const info = {}
      if (props.shipping_days_configurator) {
        const dates = props.shipping_days_configurator.props.day_confs.reduce((acc, obj) => {
          const dayType = obj.props.day_type.value;
          const dayProps = obj.props.day.props;

          const targetArray = 'business_day' === dayType ? acc.business : acc.holiday;

          if (dayProps.date_at) {
            targetArray.dates.push(dayProps.date_at)
            info[dayProps.date_at] = dayProps.title
          }
          if (dayProps.days_of_week) {
            targetArray.days.push(dayProps.days_of_week)
          }
          return acc
        }, { business: { days: [], dates: [] }, holiday: { days: [], dates: [] } })
        this.info = info
        return dates
      }
      return disable
    },
  },
  mounted() {
    this.handleInput()
  },
  methods: {
    async getSchedule() {
      const { data } = (await getSchedule.execute({
        filters: {
          settlement_id: this.order.city.id,
          shipping_service_id: this.order.shipping_service.id,
        },
      }))
      this.config.enable = Object.keys(data.schedule).reduce((acc, obj) => {
        if (data.schedule[obj].is_available) {
          acc.push(obj)
        }
        return acc
      }, [])
      this.datesConfig = data.schedule
      console.log((Object.keys(data.schedule).find(e => data.schedule[e].is_available)), '+++++++==========');
      this.form.delivery_date = (Object.keys(data.schedule).find(e => data.schedule[e].is_available))
      this.selected = null
    },








    onDayCreate(dObj, dStr, fp, dayElem) {
      const t = this.getDateConfig(dayElem.dateObj)
      if (!t) return
      let title = ''
      if (!t.is_available) {
        title = this._('Выходной день')
      }
      dayElem.title = title
    },
    handleInput() {
      this.$emit('update', this.form)
    },
    select(item) {
      this.selected = item
    },
    getValidMinDate(date) {
      let newDate = add(new Date(date), {
        days: 1,
      })
      const isInvalid = this.getBlocked(newDate)
      if (isInvalid) {
        newDate = this.getValidMinDate(newDate)
      }
      return newDate
    },
    setMinDate() {
      this.config.disable = [day => this.getBlocked(day)]
      const date = new Date()

      // const isInvalidInit = this.getBlocked(date)
      // if (isInvalidInit) {
      //   date = this.getValidMinDate(date)
      // }

      // const hours = this.order.shipping_service.props.hours_to_delivery_date

      // for (let x = hours; 0 < x; x--) {
      //   const d = add(date, {
      //     hours: 1,
      //   })
      //   const isInvalid = this.getBlocked(d)
      //   if (isInvalid) {
      //     date = this.getValidMinDate(d)
      //   }
      // }
      // console.log(date, 'DATE <<<<>>>>');

      let newDate = add(date, {
        hours: this.order.shipping_service.props.hours_to_delivery_date,
      })
      const isInvalid = this.getBlocked(newDate)
      if (isInvalid) {
        newDate = this.getValidMinDate(newDate)
      }
      this.form.delivery_date = newDate
      this.config.minDate = newDate
    },
    format(day) {
      console.log(day, '""""""');
      return lightFormat(day, 'dd.MM.yyyy')
    },
    checkBlocked(day) {
      const d1 = lightFormat(day, 'dd.MM.yyyy')
      const dayHoliday = this.refactorDates.holiday.days.includes(DAY_OF_WEEK[day.getDay()])
      const dateHoliday = this.refactorDates.holiday.dates.includes(d1)
      const dayBusiness = this.refactorDates.business.days.includes(DAY_OF_WEEK[day.getDay()])
      const dateBusiness = this.refactorDates.business.dates.includes(d1)
      return { dayHoliday, dateHoliday, dayBusiness, dateBusiness }
    },
    getDateConfig(day) {
      // console.log(day, '+++++++++++++++++++++++1getDateConfig');
      let d = day
      try {
        d = lightFormat(day, 'yyyy-MM-dd')
      } catch (e) {
        d = day
      }
      return this.datesConfig[d]
    },
    getBlocked(day) {
      const { dayHoliday, dateHoliday, dayBusiness, dateBusiness } = this.checkBlocked(day)

      if ((dayHoliday || dateHoliday) && (dayBusiness || dateBusiness)) {
        return false
      }
      return dayHoliday || dateHoliday
    },
    setUrgent() {
      const i = this.getDateConfig(this.form.delivery_date)
      if (!i) return
      this.form.is_urgent = i.is_urgent
      this.handleInput()
    },
  },
}
</script>
