<template lang="pug">
  include /mixins
  +b.loyalty-program__form-bg
    +e.form-wrapper(
    )
      +b.P.ds-caption.--size_lg.--size_md-lg.--size_lg-xl.--color_grey.--medium.--appearance_center.tt-loyalty-form-title(style='line-height:normal') {{ _('Участвуйте в программе лояльности') }}
      +b.P.ds-caption.--size_md.--size_sm-lg.--color_grey.--regular.--appearance_center.tt-space_pt--1.tt-loyalty-form-title {{ _('И пользуйтесь всеми преимуществами') }}
      +b.VALIDATION-OBSERVER(
        ref="validator",
        v-slot="{ valid }",
        tag="form",
      )
        ui-loader(v-if='isLoad')
        row
          template(v-for='{ rules, name, label, mask, cols, type, tag } in form_fields')
            +b.tt-space_mt--8.--5-lg.--8-xl.--10-mac.CELL.relative(
              :cols="cols || '12'"
              :class='`field_${name}`'
            )
              tag.static-control(
                :tag='tag || "d-control-input"'
                :rules="rules",
                :input-label='label'
                v-model='form[name]',
                :ref='name'
                :name='name'
                :type='type || "text"'
              )
          +b.tt-space_mt--5.--10-xl.CELL(cols='12')
            +b.tt-button.BUTTON(
              type='submit'
              :disabled='isLoad',
              @click.prevent='prepareData(valid)'
            )
              +e.title.P {{ _('подать заявку') }}
      slot(name='after_text')
        +b.tt-space_mt--7.--5-lg.--7-xl
          +b.ds-caption--size_sm.--color_accent.--appearance_center.P {{ _('Програма TOTIS CLUB - наша подяка Вам за те, що обираєте Totis') }}
</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'
import { createFeedback } from '@api/feedback.service'

import {
  AnswerMessage,
} from '@utils/submit'

export default {
  name: 'LoyaltyProgramForm',
  mixins: [FormMixin],
  data() {
    return {
      form_fields: [
        {
          name: 'name',
          label: this._('Ваше имя'),
          rules: 'required',
          cols: '12 6-md',
        },
        {
          name: 'last_name',
          label: this._('Фамилия'),
          rules: 'required',
          cols: '12 6-md',
        },
        {
          name: 'phone',
          label: this._('Телефон'),
          rules: 'required|phone',
          tag: 'phone-input',
          space: 5,
        },
        {
          name: 'email',
          label: this._('E-mail'),
          rules: 'required|email',
          type: 'email',
          space: 5,
        },
      ],
      form: {
        name: window.request_name,
        last_name: window.request_lastname,
        subject: 100,
        email: window.request_email,
        phone: window.request_phone,
        message: this._('Программа лояльности'),
      },
      non_field_errors: '',
      isLoad: false,
    }
  },
  created() {
    const state = new URLSearchParams(window.location.search)
    const name = state.get('first_name')
    if (name) this.form.name = state.get('first_name')
    const last_name = state.get('last_name')
    if (last_name) this.form.last_name = state.get('last_name')
    const phone = state.get('phone')
    if (phone) {
      this.form.phone = phone.indexOf(' ') == 0 ? phone.replace(' ', '+') : phone
    }
    const email = state.get('email')
    if (email) this.form.email = state.get('email')
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      this.submit(valid, this.form)
    },
    send(data) {
      return createFeedback.execute({}, data)
        .then(({ data }) => {
          AnswerMessage(data, this)
          this.$emit('close')
          this.$refs.validator.reset()
          this.form = {
            name: '',
            last_name: '',
            subject: 100,
            email: '',
            phone: '',
            message: this._('Программа лояльности'),
          }
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>
