<template lang="pug">
include /mixins
+b.new-set-cart-view.product_in_cart(v-if='firstSet && firstSet.base')
  +b.tt-new-sale-view--without-hover
    +e.content
      +e.img-wrap
        +e.img.IMG(src!='/static/img/cart_gift.svg')
      +e.info
        +e.title.P  {{ campaign && campaign.props && campaign.props.title }}
  template(v-for='product in setPreviews')
    +b.tt-new-sale-products
      +e.img-wrap
         +b.tt-picture--size_contain.product__img--mini.PICTURE.relative
            source(
              type="image/webp"
              :srcset="product.element.props.img && product.element.props.img.catalog_preview_webp"
            )
            +e.body.IMG(
              v-if='product.element.props.img && product.element.props.img.catalog_preview'
              :src='product.element.props.img && product.element.props.img.catalog_preview',
              :alt='product.element.props.title',
            )
            +e.body.IMG(
              v-else
              src='https://via.placeholder.com/60x60',
            )
      +e.content
        +e.line
          +e.brand.P {{ product.element.props.product.props.brand.props.title }}
          +e.brand.P.is-visible-xl {{ _('Артикул:') }} {{ product.element.props.vendor_code }}
          +e.brand.P.is-disable-xl {{ product.element.props.vendor_code }}
        +e.line
          +e.inner
            +e.title
              +b.ds-caption--size_sm.--size_sm-xl.--bold.A.tt-link--variant_start.A(
                :class='{ "is-disabled": !product.element.props.show_in_catalog }'
                :href='product.element.props.url'
              ) {{ product.element.props.product.props.title }}
            +b.g-row--appearance_nowrap.--align_center
              +b.g-cell.g-cols(
                style='flex-shrink: 0'
                v-if='product.element.props.weight.props.img || product.element.props.weight.props.color'
              )
                +b.product-weight-color-trigger
                  +e.color.IMG(
                    v-if='product.element.props.weight.props.img'
                    :src='product.element.props.weight.props.img'
                  )
                  +e.color(
                    v-else
                    :style='{ "--color": product.element.props.weight.props.color }'
                  )
              +b.g-cell.g-cols
                +b.ds-caption--size_2xs.--color-relief.--medium.P(v-if='product.element.props.weight.props.color_number') {{ product.element.props.weight.props.color_number }} ({{ product.element.props.weight.props.name }})
                +b.ds-caption--size_2xs.--color-relief.--medium.P(v-else) {{ product.element.props.weight.props.name }}

          +e.counter
            counter(
              :amount='product.quantity',
              @change='$emit("updateCart", { info: $event, campaign: campaignInfo, set })'
              :max-count='product.element.props.stock'
              :show-error='product.element.stockInfo && !product.element.stockInfo.is_valid && product.element.stockInfo.quantity > product.element.stockInfo.stock_quantity_1c'
              :disable='true'
            )
  +e.btn(v-if='firstSet.base.element.props.select_gift')
    modal-trigger(
      name='SetView',
      :set='set',
      :campaign="campaignInfo"
      url='Cart/SetView',
      :classes='["cart-modal"]'
    )
      template(#default='{ open }')
        +b.g-row
          +b.g-cell.g-cols
            +b.tt-link--styling_default.--accent.ds-caption--size_2xs.BUTTON(
              @click.prevent='openGiftVariants'
            )
              +e.title--mini.SPAN {{ _('Выбрать другой подарок') }}

//- +b.set-cart-view.card-product-inner.product_in_cart(v-if='firstSet && firstSet.base')
//-   +e.imgs
//-     row(appearance='spaced')
//-       template(
//-         v-for='(item, index) in setPreviews'
//-       )
//-         cell(cols='3')
//-           +e.img
//-             +b.tt-picture--size_auto.--size_auto-h.product__img--mini.PICTURE.relative
//-               source(
//-                 type="image/webp"
//-                 :srcset="item.element.props.img && item.element.props.img.catalog_preview_webp"
//-               )
//-               +e.body.IMG(
//-                 v-if='item.element.props.img && item.element.props.img.catalog_preview'
//-                 :src='item.element.props.img && item.element.props.img.catalog_preview',
//-                 :alt='item.element.props.title',
//-               )
//-               +e.body.IMG(
//-                 v-else
//-                 src='https://via.placeholder.com/60x60',
//-               )
//-   +e.content(v-if='set && set.length && firstSet && firstSet.base')
//-     row(
//-       justify='between'
//-       align='center'
//-       appearance='nowrap'
//-     )
//-       cell(
//-         cols=''
//-       )
//-         +b.ds-caption--size_sm.--bold.--color_accent.P {{ _('Подарок') }}
//-       cell(
//-         cols=''
//-       )
//-         counter(
//-           :amount='firstSet.base.parameters.gift_amount',
//-           @change='$emit("updateCart", { info: $event, campaign: campaignInfo, set })'
//-           :max-count='maxCount'
//-           :show-error='l && maxCount < l'
//-           :disable='true'
//-         )
//-   +e.summary.ds-caption--size_2xs.--regular.P(v-if='firstSet && firstSet.base') {{ _('Вам доступно') }} {{ firstSet.base.parameters.gift_amount }} {{ $tc('товар', firstSet.base.parameters.gift_amount) }} {{ _('в подарок.') }}
//-     modal-trigger(
//-       name='SetView',
//-       :set='set',
//-       :campaign="campaignInfo"
//-       url='Cart/SetView',
//-       :classes='["cart-modal"]'
//-     )
//-       template(#default='{ open }')
//-         +b.tt-link--styling_default.--accent.ds-caption--size_2xs.SPAN(
//-           @click.prevent='openGiftVariants'
//-         ) {{ _('посмотреть подробнее') }}
//-   +e.error(
//-     v-if='maxCount < l'
//-   )
//-     +b.ds-caption--size_2xs.--regular.--color_red.P(
//-     ) {{ _('Товаров в подарок не хватает.') }}
//-     div(style='display: inline-flex')
//-       +b.d-control-static-input--appearance_inline.TAG(
//-         :tag='"input"'
//-         :input-label='_("Не участвую")'
//-         :key='`key_without_special_offer`'
//-         v-model='val'
//-         :ref="'limited_gifts_'+id"
//-         :id="'limited_gifts_'+id"
//-         :name="'limited_gifts_'+id"
//-         type='checkbox'
//-         :value='true'
//-         @change='clearValidation'
//-       )
//-       label.input-group__input(
//-         :for="'limited_gifts_'+id"
//-       )
//-         +b.ds-caption--size_xs.SPAN {{ _("Я согласен(на) на меньшее количество акционных товаров") }}

</template>

<script>
import { mapState, mapActions } from 'vuex'

import GiftChangeVariants from '@app/Cart/GiftChangeVariants'

const SET_PREVIEWS_COUNT = 8

export default {
  name: 'CartGiftPreview',
  props: ['set', 'id'],
  data() {
    return {
      currency: window.currency,
      val: false,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart', 'cartIsLoad',
    ]),
    campaignInfo() {
      const [, id, number] = this.id.split('_')
      if (this.cart.parameters.complects_info && this.cart.parameters.complects_info.length === 0) {
        return this.cart.parameters.complects_info
          .find(i => i.campaign_id === Number(id) && i.number_complect === Number(number))
      }
      const c = this.cart.groups.find(e => e.base.element.props.campaign && e.base.element.props.campaign.id == id )
      if (c) {
        return c.base.element.props.campaign
      }
      return []
    },
    campaign() {
      const [, id, number] = this.id.split('_')
      console.log(id);
      let c = Object.keys(this.cart.gifts_groups).map(key => {
        const r = this.cart.gifts_groups[key].find(e => e.base.element.props.campaign && e.base.element.props.campaign.id == id )
        if (r) return r
      }).filter(e => e)
      console.log(c, 1);
      if (!c || c.length && !c[0]) {
        c = Object.keys(this.cart.gifts).map(key => {
          const r = this.cart.gifts[key].find(e => e.base.element.props.campaign && e.base.element.props.campaign.id == id )
          if (r) return r
        })
      }
      console.log(c, 2);

      if (c && c.length && c[0]) {
        return c[0].base.element.props.campaign
      }
      return {}
    },
    maxCount() {
      return Math.min(...this.firstSet.relations.map(i => i.element.stock_info.stock_quantity_1c))
    },
    l() {
      const g = this.firstSet.relations.reduce((acc, obj) => {
        if (!obj.element.stock_info.is_valid) {
          acc.push([obj.quantity, obj.element.stock_info.stock_quantity_1c])
        }
        return acc 
      }, [])
      if (g.length) {
        return g[0][0]
      }
      return []
    },
    setPreviews() {
      if (!this.set) return []
      const [s] = JSON.parse(JSON.stringify(this.set))
      return s.relations
    },
    firstSet() {
      if (!this.set) return {}
      return this.set[0]
    },
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
      'rmFromValidation',
      'addFromValidation',
    ]),
    clearValidation(e) {
      this.val = e.target.checked
      if (this.val) {
        const ids = this.firstSet.relations.map(i => i.id)
        ids.forEach(id => this.rmFromValidation(id))
        return
      }
      this.addFromValidation()
    },
    openGiftVariants() {
      const pr = arg => new Promise((resolve, reject) => {
        if (arg) {
          resolve(arg)
          return
        }
        reject(new Error('Promise is rejected'))
      }).then(res => {
        this.getCart()
      })
      this.$modal.show(
        GiftChangeVariants,
        {
          id: this.set[0].base.id,
          promise: pr,
          count: this.set[0].base.parameters.gift_amount,
          set: this.firstSet,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
</script>
