<template lang="pug">
  include /mixins
  +b.variants-view(v-if='isShow')
    //- +e.title
    //-   +b.variants-title
    //-     +e.icon.mdi.mdi-chevron-right
    //-     +e.text.P {{ _('Выберите удобный способ доставки') }}
    +b.g-row
      +b.g-cell.g-cols--12.tt-space_mt--6(
        :class='`g-cols--${city_config.size}-xl`'
      )
        tag(
          :tag='city_config.tag'
          v-model='form[city_config.name]'
          :input-label='city_config.title',
          @input='check'
          :rules='city_config.rules'
          v-bind='city_config.props'
          v-on='city_config.events'
          :name='city_config.name'
        )
          template(
            #afterList=''
          )
            div(v-observe-visibility="city_config.afterList" v-if="city_config.props.pagination.next")
            +b.um-space_pt--3
          template(
            #option='{ option }'
          )
            p {{ getCityLabel(option) }}
    +b.g-row(v-if='form[city_config.name]')
      +b.g-cell.g-cols--12.tt-space_mt--6(
        :class='`g-cols--${delivery_config.size}-xl`'

      )
        tag(
          :tag='delivery_config.tag'
          v-model='form[delivery_config.name]'
          :input-label='delivery_config.title',
          @input='() => {check(); clearOther()}'
          :rules='delivery_config.rules'
          v-bind='delivery_config.props'
          v-on='delivery_config.events'
          :name='delivery_config.name'
        )
        +b.tt-space_mt--2.ROW(
          align='center'
          justify='start'
          v-if='isFree && freeShippingMessage'
        )
          cell(cols)
            row
              cell(cols)
                +b.ds-caption--color_accent.--size_sm.P
                  span.icon-shipping-car
              cell(cols)
                +b.ds-caption--size_sm.--color_accent.P {{ _('Доставка бесплатно') }}
        +b.tt-space_mt--2.ROW(
          align='center'
          justify='start'
          v-if='!isFree && shippingPrice'
        )
          cell(cols)
            row
              cell(cols)
                +b.ds-caption--color_accent.--size_md.P
                  span.icon-shipping-box
              cell(cols)
                +b.ds-caption--size_sm.--color_accent.P {{ _('Стоимость доставки:') }} #[span(v-html='getSplittedHtml(shippingPrice)')]
              cell(cols='12')
                +b.ds-caption--size_xs.--light.P {{ deliveryDescription }}
    +e.list
      +b.g-row
        +b.g-cell.g-cols--12
          transition-group(name="slidedown")
            template(
              v-if='form.shipping_service && form.shipping_service.id'
            )
              +b.g-row(key='field2')
                template(v-for='({ props: { field, is_required } }) in form.shipping_service.props.conf_fields.filter(e => !e.props.recipient_info)')
                  +b.g-cell.g-cols--12.tt-space_mt--6(v-if='config[field.props.label]')
                    tag(
                      :tag='config[field.props.label].tag',
                      :ref='field.props.label', :name='field.props.label',
                      :rules="{ 'required': is_required, ...getValidation(field.props.validation) }",
                      :input-label='field.props.title', v-model='form[field.props.label]',
                      @input='check'
                      v-bind='config[field.props.label].props'
                      v-on='config[field.props.label].events'
                      :key='`_${config[field.props.label].name}`'
                    )
                      template(
                        #afterList=''
                      )
                        div(v-observe-visibility="config[field.props.label].afterList" v-if="config[field.props.label].props.pagination.next")
                        +b.um-space_mt--3
              +b.g-row--appearance_spaced.tt-space_mt--3(
                key='checkbox',
                v-if='isAuth && order'
              )
                +b.g-cell.g-cols
                  +b.d-control-static-input--appearance_inline.TAG(
                    :rules='{ required: { allowFalse: false } }',
                    :tag='"input"'
                    :input-label='_("Сохранить адрес?")'
                    :key='`key_save_address`'
                    :checked.props='!!form.saveAddress'
                    :id='`save_address`'
                    :value.props='false'
                    @change='setSaveAddress'
                    :ref='`save_address`'
                    type='checkbox'
                    name='save_address'
                  )
                  label.input-group__input(
                    :for='`save_address`'
                  ) {{ _('Сохранить адрес?') }}
</template>

<script>

import { mapState } from 'vuex'

import {
  getShipping,
  getNovaposhtaCities,
  getNovaposhtaWarehouses,
  getNovaposhtaStreets,
  BASE_NOVAPOSHTA_CITIES_LINK,
} from '@api/order.service'

import {
  BrandsListResoure,
} from '@api/vendor.service'

import {
  getCookie,
} from '@utils/cookies'

import { getPaginatedProps } from '@utils/valuesGetter'

import {
  COUNTRY_CODE_COOKIE_NAME,
  UKRAINE_CODE,
} from '@app/consts'

// import MapViewer from './MapViewer'

const params = '{?search,offset,country_available__iso_code}'

const RESOURCES = {
  courier: BrandsListResoure,
  pickup: BrandsListResoure,
  new_post_courier: getNovaposhtaCities,
  new_post_pickup: getNovaposhtaCities,
}

const FIRST_PAGE_OFFSET = 0

// const SERVICE_DEPENDENCE_FIELDS = ['street', 'street_name', 'house', 'apartment', 'building', 'warehouse', 'internal_warehouse', 'zip_code']

const getCityLabel = opt => {
  const { props } = opt
  return props.description
}

export default {
  name: 'OrderDelivery',
  keyGetter: x => `${x.type}:${x.id}`,
  UKRAINE_CODE,
  props: {
    initial: {},
    hasInit: {},
    order: {},
    country: {},
    is_not_available: {},
    isShow: {
      default: true,
    },
    for_order: {
      default: true,
    },
    isFree: {
      default: false,
    },
    shippingPrice: {},
    deliveryDescription: {},
  },
  data() {
    return {
      isAuth: window.authent,
      initOption: {
        $isDisabled: true,
        caption: this._('-выберите'),
        city: this._('-выберите'),
        props: {
          description: this._('-выберите'),
          description_ru: this._('-выберите'),
          address: this._('-выберите'),
          city: this._('-выберите'),
          streets_type: '',
        },
      },
      cityQuery: '',
      streetQuery: '',
      variants: [],
      selected: '',
      form: {
        city: '',
        street_name: '',
        shipping_service: '',
        street: '',
        house: '',
        apartment: '',
        warehouse: '',
        building: '',
        internal_warehouse: '',
        zip_code: '',
        saveAddress: false,
      },
      city_config: {
        size: 'g-cols--12-xl',
        title: this._('Город'),
        rules: 'required',
        name: 'city',
        tag: 'd-control-multiselect',
        events: {
          open: $event => this.getCities($event, 'new_post_courier', 'city'),
          'search-change': $event => this.getCities($event, 'new_post_courier', 'city'),
          input: () => this.getShippingVariantsAndClear(),
        },
        afterList: visible => { this.getCities('', 'new_post_courier', 'city', true, visible) },
        props: {
          pagination: {},
          options: [],
          label: 'caption',
          customLabel: getCityLabel,
          searchable: true,
          preserveSearch: false,
          internalSearch: false,
          search: '',
          loading: false,
        },
      },
      delivery_config: {
        size: 'g-cols--12-xl',
        title: this._('Варианты доставки'),
        rules: 'required',
        name: 'shipping_service',
        tag: 'd-control-multiselect',
        events: {
          input: () => this.clearFields(),
        },
        props: {
          options: [],
          label: 'caption',
          disabled: false,
        },
      },
      config: {
        street: {
          size: 'g-cols--12-xl',
          title: this._('street'),
          rules: 'required',
          name: 'street',
          tag: 'd-control-multiselect',
          events: {
            open: $event => this.getNovaposhtaStreets($event, 'street'),
            'search-change': $event => this.getNovaposhtaStreets($event, 'street'),
          },
          afterList: () => { this.getNovaposhtaStreets('', 'street', true) },
          props: {
            customLabel: ({ props }) => `${props.streets_type} ${props.description}`,
            options: [],
            pagination: {},
            label: 'caption',
            searchable: true,
            preserveSearch: true,
            disabled: false,
          },
        },
        street_name: {
          size: 'g-cols--12-xl',
          title: this._('Улица'),
          rules: 'required',
          name: 'street_name',
          tag: 'd-control-input',
        },
        house: {
          title: this._('house'),
          size: 'g-cols--6-xl',
          rules: 'required',
          name: 'house',
          tag: 'd-control-input',
        },
        apartment: {
          size: 'g-cols--6-xl',
          title: this._('apartment'),
          name: 'apartment',
          tag: 'd-control-input',
        },
        building: {
          size: 'g-cols--6-xl',
          title: this._('Корпус'),
          name: 'building',
          tag: 'd-control-input',
        },
        zip_code: {
          size: 'g-cols--6-xl',
          title: this._('Postal code'),
          name: 'zip_code',
          rules: 'required|max:10',
          tag: 'd-control-input',
          type: 'number',
        },
        internal_warehouse: {
          size: 'g-cols--12-xl',
          title: this._('Представительство'),
          rules: 'required',
          name: 'internal_warehouse',
          tag: 'd-control-multiselect',
          events: {
            open: $event => this.getBranches($event, 'internal_warehouse'),
          },
          props: {
            options: [],
            pagination: {},
            label: 'city',
            preselectFirst: true,
            customLabel: ({ props }) => `${props.address}`,
          },
        },
        warehouse: {
          size: 'g-cols--12-xl',
          title: this._('Отделение'),
          rules: 'required',
          name: 'warehouse',
          tag: 'd-control-multiselect',
          events: {
            open: $event => this.getNovaposhtaWarehouses($event, 'warehouse'),
            'search-change': $event => this.getNovaposhtaWarehouses($event, 'warehouse'),
          },
          afterList: () => { this.getNovaposhtaWarehouses('', 'warehouse', true) },
          props: {
            options: [],
            pagination: {},
            label: 'caption',
            customLabel: ({ props }) => props.description,
            searchable: true,
            preserveSearch: true,
            preselectFirst: true,
            disabled: false,
          },
        },
        // {
        //   size: 'g-cols--12-xl',
        //   title: this._('warehouse'),
        //   rules: 'required',
        //   name: 'map-viewer',
        //   tag: () => import('./MapViewer'),
        //   events: {
        //     selectCity: $event => this.setCity($event, 'pickup', 'internal_warehouse', 'map-viewer'),
        //     'hook:created': () => this.getBranches('', 'pickup', 'internal_warehouse'),
        //     open: $event => this.getBranches($event, 'pickup', 'internal_warehouse'),
        //   },
        //   props: {
        //     options: [],
        //     pagination: {},
        //     modal: {
        //       name: 'WarehousesList',
        //     },
        //   },
        // },
      },
      changed: false,
    }
  },
  computed: {
    ...mapState('staff', [
      'level',
    ]),
    freeShippingMessage() {
      return window.authent ? this.level?.props?.free_shipping_information : window.free_shipping_information_for_anonim
    },
    currentCountry() {
      return this.country || getCookie(COUNTRY_CODE_COOKIE_NAME)
    },
  },
  watch: {
    'form.city': {
      handler() {
        this.disabledMethods()
      },
    },
    initial: {
      handler() {
        if (!this.hasInit) return
        this.getShippingVariants()
        const d = JSON.parse(JSON.stringify(this.initial))
        Object.keys(this.form).forEach(key => {
          this.form[key] = d[key]
        })
      },
      immediate: true,
      deep: true,
    },
    country: {
      handler(nval, oval) {
        if (nval) {
          if (!this.changed) {
            this.form.city = this.initial.city
          } else {
            this.city_config.props.options = []
            this.form.city = ''
            this.clearFields()
          }
          this.changed = true
        }
      },
      immediate: true,
    },
  },
  created() {
  },
  mounted() {
    this.disabledMethods()
  },
  methods: {
    getCityLabel,
    getValidation(arr) {
      return arr.reduce((acc, item) => {
        if (item.props.rule) {
          acc = { ...acc, ...item.props.rule }
        }
        return acc
      }, {})
    },
    clearFields() {
      this.form.street = ''
      this.form.street_name = ''
      this.form.house = ''
      this.form.apartment = ''
      this.form.warehouse = ''
      this.form.building = ''
      this.form.zip_code = ''
      this.$emit('update', this.form)
    },
    disabledMethods() {
      this.delivery_config.props.disabled = !this.form.city
    },
    async getShippingVariants() {
      const settlements = this.form.city.id
      const getParams = {
        settlements,
        country_available: this.country || getCookie(COUNTRY_CODE_COOKIE_NAME),
      }
      if (this.for_order) {
        getParams.for_preorder = !!window.isPreorder
      }
      const { data: { items } } = (await getShipping.execute(getParams))
      this.variants = items
      this.delivery_config.props.options = this.variants
      this.delivery_config.props.options.unshift(this.initOption)
    },
    async getShippingVariantsAndClear() {
      await this.getShippingVariants()
      this.clearAfterRecieveShipping()
    },
    clearAfterRecieveShipping() {
      this.form.street = ''
      this.form.house = ''
      this.form.street_name = ''
      this.form.apartment = ''
      this.form.warehouse = ''
      this.form.shipping_service = ''
      this.form.building = ''
      this.form.zip_code = ''
      this.$emit('update', this.form)
    },
    async getCities(event, service, key, push, visible) {
      this.city_config.params = {
        country_available__iso_code: this.country || getCookie(COUNTRY_CODE_COOKIE_NAME)
      }
      getPaginatedProps(BASE_NOVAPOSHTA_CITIES_LINK, event, this.city_config, visible, params)
      // let offset = this.city_config.props.pagination.offset +
      //   this.city_config.props.pagination.limit
      // if (!push) offset = FIRST_PAGE_OFFSET
      // this.cityQuery = event

      // const {
      //   data: {
      //     items, pagination,
      //   },
      // } = (await RESOURCES[service]
      //   .execute({
      //     search: this.cityQuery,
      //     offset,
      //     country_available__iso_code: this.country || getCookie(COUNTRY_CODE_COOKIE_NAME),
      //   }))
      // this.city_config.props.pagination = pagination
      // if (push) {
      //   this.city_config.props.options.push(...items)
      // } else {
      //   this.city_config.props.options = items
      //   this.city_config.props.options.unshift(this.initOption)
      // }

    },
    async getNovaposhtaStreets(event, key, push) {
      const field = this.config[key]

      let offset = (field.props.pagination.offset +
        field.props.pagination.limit) || 0
      if (!push) offset = FIRST_PAGE_OFFSET
      this.streetQuery = event
      const { id } = this.form.city
      const {
        data: {
          items,
          pagination,
        },
      } = (await getNovaposhtaStreets.execute({
        search: event,
        city: id,
        offset,
      }))

      this.config[key].props.pagination = pagination
      if (push) {
        this.config[key].props.options.push(...items)
      } else {
        this.config[key].props.options = items
        this.config[key].props.options.unshift(this.initOption)
      }

      // this.getParentKeys(service, key, child_key)
    },
    async getBranches(event, key) {
      const {
        data: {
          items,
        },
      } = (await RESOURCES[this.form.shipping_service.props.service.value]
        .execute({ search: event, settlement: this.form.city.id }))
      this.config[key].props.options = items
      this.config[key].props.options.unshift(this.initOption)
    },
    async getNovaposhtaWarehouses(event, key, push) {
      const field = this.config[key]

      const { id } = this.form.city
      let offset = (field.props.pagination.offset +
        field.props.pagination.limit) || 0
      if (!push) offset = FIRST_PAGE_OFFSET
      const {
        data: {
          items,
          pagination,
        },
      } = (await getNovaposhtaWarehouses.execute({
        search: event,
        city: id,
        country_available: this.country || getCookie(COUNTRY_CODE_COOKIE_NAME),
        is_not_available: this.is_not_available,
        offset,
        shipping_service: this.form.shipping_service && this.form.shipping_service.id,
      }))

      this.config[key].props.pagination = pagination
      if (push) {
        this.config[key].props.options.push(...items)
      } else {
        this.config[key].props.options = items
        this.config[key].props.options.unshift(this.initOption)
      }
    },
    check() {
      this.$emit('update', this.form)
    },
    setSaveAddress() {
      this.$nextTick(() => {
        this.form.saveAddress = !this.form.saveAddress
        this.check()
      })
    },
    clearOther() {

    },
    setCity(event, service, key) {
      this.form[key] = event
    },
    getParentKeys(service, parent_key, key) {
    },

  },
}
</script>
