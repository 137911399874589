<template lang="pug">
  include /mixins
  +b.ds-panel--space_xl
    +e.element--offset_vertical
      ui-loader(v-if='isLoad')
      +b.VALIDATION-OBSERVER(
        ref="validator",
        v-slot="{ valid, errors }",
        tag="form",
      )
        +b.g-row--justify_between-xl.--space_3xl-xl.--space_4xl-fhd.--appearance_reverse-till-xl
          +b.g-cell.g-cols--12.--7-xl
            +b.tt-space_mb--5-xl.USER-TYPE-TRIGGER(
              @user:type:update='isOldUser = $event'
            )
            div(v-if='isOldUser')
              tag(
                tag='login',
                :no-redirect='true',
              )
            steps-controller(
              :valid='validStep'
              v-else
              @stepChange='saveStep'
            )
              template(#default='{ current, change }')
                div()
                  single-step-controller(
                    v-for='el in $options.widgetsMap'
                    :key='el.key',
                    :step='change'
                    v-show='el.condition(el.step, currentStep, user)'
                  )
                    keep-alive
                      component(
                        :is="el.type"
                        :key='`tag${el.key}`'
                        @update='update($event)',
                        @valid='validateStep($event, change)',
                        @user:save='saveUser'
                        @user:info='saveUserInfo($event, change)'
                        @update:isFreeDelivery='checkFreeDelivery'
                        :is-old-user='isOldUser'
                        :cart-change='cartChangeTimestamp'
                        :user='user'
                        :info='info'
                        :order='order'
                        :bonuses-info='bonusesInfo'
                        :isBonusesAvailable='isBonusesAvailable'
                        :is-free-delivery='isFreeDelivery',
                        :shipping-price='Number(shippingPrice)',
                        :delivery-description='deliveryDescription',
                      )
                        template(
                          #default=''
                        )
                          +b.g-row--justify_end
                            +b.g-cell.g-cols--12
                              d-control-textarea.is-textarea(
                                :input-label='_("Комментарий")'
                                rows='4'
                                error-classes='error-label--top'
                                v-model='order.client_comment',
                                name='client_comment'
                              )
                            +b.g-cell.g-cols--12.tt-space_mt--6
                              d-control-static-input(
                                :input-label='_("Мне можно не звонить для подтверждения заказа")'
                                v-model='dont_call_me',
                                ref='call_me'
                                class='control-descriptor--inline'
                                id='call_me'
                                type='checkbox'
                                name='call_me',
                              )
                            +b.g-cell.g-cols--12(v-if='!$options.isPreorder')
                              special-offer(
                                v-model='order.offers'
                              )
          +b.g-cell.g-cols--12.--5-xl
            single-step-controller(
              v-for='el in $options.rightSide'
              :key='el.key'
            )
              tag(
                :tag="el.type"
                :key='`tag${el.key}`'
                :bonus='order.bonus_sum'
                :bonus-info='bonusesInfo'
                @update='update($event)'
                @cart:update='afterCartChange'
                :is-free-delivery='isFreeDelivery',
                :shipping-price='Number(shippingPrice)',
                :delivery-description='deliveryDescription',
              )
            template(v-if='showPromoAndFinishOrderBtn')
              template(v-if='!$options.isPreorder')
                promocode(
                  v-if='isPromocodeAvailable'
                  :phone='order.phone'
                  @promochange='getBonuses'
                )
                bonus(
                  :bonus='order.bonus_sum'
                  v-if='isBonusesAvailable'
                  @input='setBonuses'
                  :cart-change='cartChangeTimestamp'
                )
              +b.g-row--justify_end(v-if='notValidOrder')
                +b.g-cell.g-cols.tt-space_mt--6
                  +b.ds-caption--size_xs.--color_red.P {{ _('Вибрана адреса містить помилки. Виправте їх або виберіть іншу адресу') }}
              +b.g-row--justify_between.--appearance_nowrap-xl.--align_center
                +b.g-cell.g-cols--12.--8-md.--8-xl.tt-space_mt--6(
                  v-if='needToAcceptRules'
                )
                  validation-provider(
                    :rules='{ required: { allowFalse: false } }',
                    v-slot="{ errors }"
                    name='agree_rules'
                  )
                    +b.d-control-input.--appearance_flexible
                      +e.element.INPUT(
                        type='checkbox'
                        name='accept_rules'
                        v-model='order.accept_rules'
                        id='accept_rules'
                      )

                      +e.LABEL.label--static(
                        for='accept_rules'
                      )
                        +b.ds-caption--regular.--size_sm.P {{ _('Я соглашаюсь с') }} &nbsp;
                          +b.tt-link--inline.--styling_default.--default.A.ds-caption--size_sm.--regular(:href='offer', target='_blank') {{ _('политикой конфиденциальности') }}
                          span &nbsp; {{ _('и') }} &nbsp;
                          +b.tt-link--inline.--styling_default.--default.A.ds-caption--size_sm.--regular(:href='rules', target='_blank') {{ _('правилами сайта') }}
                      +b.error-label--top.LABEL(v-if="errors.length") {{ errors[0] }}
                +b.g-cell.g-cols--12.--narrow-md.--4-xl.tt-space_mt--6
                  +b.tt-button--appearance_free-md.--appearance_fixed-md.--product.LOADER-BTN(
                    :action='() => prepareData(valid, cartIsValid)'
                    :title="_('Оформить заказ')"
                    :load='isOrderLoaded'
                    :disabled='notValidOrder'
                  )
    +b.ds-panel--space_xl()
      +e.element--offset_vertical.--indent_inside
      +e.element--offset_vertical.--indent_inside
      +e.element--offset_vertical.--indent_inside
</template>

<script>
/* eslint-disable prefer-template */
/* eslint-disable camelcase */
/* eslint-disable no-trailing-spaces */

import { mapGetters, mapState, mapActions } from 'vuex'

import { format } from 'date-fns'

import {
  isEmpty,
} from '@aspectus/vue-utils'
import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import SpecialOffer from '@app/Order/Steps/Parts/SpecialOffer'

import {
  PreOrderCreate,
  OrderCreate,
  checkFreeShipping,
} from '@api/order.service'

import {
  addressBookAdd,
} from '@api/staff/address.service'
import {
  userUpdateRequest,
} from '@api/staff.service'

import {
  AnswerMessage,
} from '@utils/submit'
import pathOr from 'ramda/src/pathOr'
import StepsController from '@app/Order/StepsController'
import SingleStepController from '@app/Order/SingleStepController'
import Cart from '@app/Order/Steps/Cart'
import PaymentAndDelivery from '@app/Order/Steps/PaymentAndDelivery'
import CartCampaignUi from '@app/Cart/CampaignUi'
import Personal from '@app/Order/Steps/Personal'
import UserTypeTrigger from '@app/Order/Steps/Parts/UserTypeTrigger'
import Login from '@app/Auth/Login'

import CartView from '@app/Cart/View'
import Bonus from '@app/Order/Bonus'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
  DEFAULT_DATE_FORMAT,
} from '@app/consts'

const { isPreorder } = window

const WIDGETS_MAP = [
  {
    type: Personal,
    key: 'personal',
    step: 1,
    condition: (step, current, user) => (step === current),
  },
  
  {
    type: PaymentAndDelivery,
    key: 'payment_and_delivery',
    condition: (step, current, user) => (step === current),
    step: 2,
  },
]
const RIGHT_SIDE_MAP = [
  {
    type: Cart,
    key: 'cart',
    size: 4,
  },
]

const LAST_STEP = 2

const SERVER_ERROR = 500
const ZERO_POINT = '0.00'
const HUNDRED_POINT = '100.00'

export default {
  name: 'OrderView',
  components: {
    StepsController,
    SingleStepController,
    UserTypeTrigger,
    Login,
    SpecialOffer,
    Bonus,
    CartCampaignUi,
  },
  mixins: [FormMixin],
  widgetsMap: WIDGETS_MAP,
  rightSide: RIGHT_SIDE_MAP,
  isPreorder,
  data() {
    return {
      selectedCountry: getCookie(COUNTRY_CODE_COOKIE_NAME),
      validStep: false,
      dont_call_me: false,
      isOldUser: false,
      isOrderLoaded: false,
      offer: window.offer,
      rules: window.rules,
      order: {
        agree_rules: false,
        call_me: false,
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone: '',
        shipping_service: null,
        payment_gateway: '',
        city: '',
        street: '',
        house: '',
        apartment: '',
        warehouse: '',
        comment: '',
        client_comment: '',
        is_urgent: false,
        delivery_first_name: '',
        delivery_last_name: '',
        delivery_middle_name: '',
        delivery_phone: '',
        offers: [],
        saveAddress: false,
        bonus_sum: null,
        email2: '',
        is_full: true,
        id_token: null,
      },
      user: {},
      info: {},
      currentStep: 1,
      isAuth: window.authent,
      isProd: window.is_prod,
      bonusesInfo: { need_payment_gateway: true },
      cartChangeTimestamp: '',
      isFreeDelivery: false,
      shippingPrice: null,
      deliveryDescription: '',
      isLoad: false,
    }
  },
  computed: {
    ...mapGetters('cart', [
      'cartIsValid',
    ]),
    ...mapState('staff', {
      level: 'level',
      globalUser: 'user',
    }),
    isPromocodeAvailable() {
      return window.authent ? this.level?.props?.allow_promocode : window.allow_promocode_for_anonim
    },
    needToAcceptRules() {
      return window.need_to_accept_service_rules_for_anonim
    },
    isBonusesAvailable() {
      const global = window.authent ? this.level?.props?.allow_to_use_bonuses : window.allow_to_use_bonuses_for_anonim
      // const local = window.authent ? this.globalUser && this.globalUser.props && this.globalUser.props.country_discount_package : true
      const hasPercent = window.authent ? pathOr(HUNDRED_POINT, ['props', 'country_discount_package', 'props', 'discount_package', 'props', 'max_percent_bonuses_for_payment'], this.globalUser) !== ZERO_POINT : true
      const hasCountryPercent = Number(window.country_max_percent_bonuses_for_payment)
      return global && (hasPercent || hasCountryPercent)
      //  || (local && )
    },
    showPromoAndFinishOrderBtn() {
      return this.currentStep === LAST_STEP
    },
    notValidOrder() {
      return this.order?.city?.props?.is_not_available || this.order?.warehouse?.props?.is_not_available || !this.order.is_full
    },
  },
  async created() {
    if (window.authent) this.getUserLevelSettings()
  },
  methods: {
    ...mapActions('cart', [
      'getStockData',
    ]),
    ...mapActions('staff', [
      'getUserLevelSettings',
    ]),
    afterCartChange() {
      this.getBonuses()
      this.getShipping()
    },
    getShipping() {
    },
    async checkFreeDelivery(value) {
      const { data: { is_free, delivery_description, shipping_price } } = (await checkFreeShipping.execute({}, { shipping_service: value.id }))
      this.isFreeDelivery = is_free
      this.deliveryDescription = delivery_description
      this.shippingPrice = shipping_price
    },
    getBonuses() {
      this.isLoad = true
      setTimeout(() => {
        this.cartChangeTimestamp = new Date()
        this.isLoad = false
      }, 3000)
    },
    setBonuses(data) {
      this.order.bonus_sum = data.bonus_sum
      this.bonusesInfo = data
    },
    saveUser(data) {
      this.user = data
    },
    saveStep(step) {
      this.currentStep = step
    },
    saveUserInfo(data, change) {
      this.info = data
      const isValid = ((this.info.isUkraine && this.info.has_middle_name) || !this.info.isUkraine) && this.info.email
      if (this.info.type_info && isValid) {
        this.currentStep = LAST_STEP
        this.validateStep({ valid: true, step: LAST_STEP }, change)
      }
    },
    validateStep({ valid, step }, change) {
      this.validStep = valid
      setTimeout(() => change(step))
    },
    update(event) {
      this.order = Object.assign(this.order, event)
    },
    async prepareData(valid, cartIsValid) {
      const v = (await this.$refs.validator.validate())
      if (!v) return
      this.isOrderLoaded = true
      try {
        if (this.selectedCountry !== getCookie(COUNTRY_CODE_COOKIE_NAME)) {
          this.showCountryChangeModal()
          return
        }
        await this.checkCart()
        this.isOrderLoaded = false
      } catch (e) {
        this.isOrderLoaded = false
        console.log(e);
      }
    },
    async checkCart() {
      return new Promise((resolve, reject) => {
        this.getStockData()
          .then(e => {
            if (!this.cartIsValid) {
              this.openCart()
              return
            }
            this.processOrder()
          }, e => {
            this.processOrder()
          })
      })
    },
    toPromise(arg) {
      if (!arg) return Promise.reject()

      return Promise.resolve(arg)
    },

    openCart() {
      const pr = f => this.toPromise(f)
        .then(() => {
          this.processOrder()
        })
      this.$modal.show(
        CartView,
        {
          isFully: false,
          promise: pr,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
          clickToClose: false,
        }
      )
    },
    async processOrder() {
      this.isOrderLoaded = true
      if (window.authent) {
        try {
          await userUpdateRequest.execute({}, {
            info: {
              email: this.order.email || this.order.email2,
              first_name: this.order.first_name,
              last_name: this.order.last_name,
              middle_name: this.order.middle_name,
              id_token: this.order.id_token,
            },
          })
        } catch (e) {
          console.log(e);
        }
      }
      const data = JSON.parse(JSON.stringify(this.order))

      data.city = pathOr(
        pathOr(
          pathOr(
            null,
            ['id'],
            this.order.city
          ),
          ['id'],
          this.order.warehouse
        ),
        ['id'],
        this.order.city
      )
      data.warehouse = pathOr(
        '',
        ['id'],
        this.order.warehouse
      )
      data.internal_warehouse = pathOr(null, ['id'], this.order.internal_warehouse)
      data.shipping_service = pathOr(null, ['id'], this.order.shipping_service)
      data.street = pathOr(null, ['id'], this.order.street)
      data.delivery_date = format(new Date(this.order.delivery_date), DEFAULT_DATE_FORMAT)
      data.call_me = this.dont_call_me
      const c = [data.client_comment]
      if (this.order.offers && this.order.offers.length) {
        c.push(this._('Спецпредложение:'))
        c.push(this.order.offers.map(o => o && o.props.title).join(', '))
      }
      data.comment = c.join(' ')
      Object.keys(data).forEach(key => {
        if (isEmpty(data[key])) delete data[key]
      })
      if (this.bonusesInfo.need_payment_gateway) {
        data.payment_gateway = pathOr(null, ['id'], this.order.payment_gateway)
      } else {
        delete data.payment_gateway
      }

      try {
        if (this.order.saveAddress) {
          const addressData = JSON.parse(JSON.stringify(data))
          addressData.first_name = addressData.delivery_first_name
          addressData.last_name = addressData.delivery_last_name
          addressData.middle_name = addressData.delivery_middle_name
          await addressBookAdd.execute({}, { ...addressData, country_available: getCookie(COUNTRY_CODE_COOKIE_NAME) })
        }
  
        delete data.saveAddress
      } catch (e) {
        console.log(e);
      }
      if (!this.order.email && this.order.email2) {
        data.email = this.order.email2
      }
      if (!this.order.bonus_sum) {
        delete data.bonus_sum
      }
      
      this.submit(true, data)
    },
    send(data) {
      const action = window.isPreorder ? PreOrderCreate : OrderCreate
      this.isOrderLoaded = true
      return action.execute({}, data)
        .then(({ data: res }) => {
          const orders = res.joint_orders
          console.log(orders, '>>>>>>>>>>>>>>');
          if (orders.length > 1) {
            window.location.href = `${window.order_success}?_state=${btoa(JSON.stringify(orders))}`
            return
          }
          AnswerMessage(res, this)
          this.$refs.validator.reset()
        }, e => {
          this.isOrderLoaded = false
          if (e.status === SERVER_ERROR) {
            const meta = {
              message: {
                title: this._('order create SERVER ERROR title'),
                text: this._('order create SERVER ERROR text'),
              },
            }
            AnswerMessage(meta, this)
          }
          return Promise.reject(e)
        })
    },
    showCountryChangeModal() {
      const meta = {
        message: {
          title: this._('Your country is changed.Page will be reloaded. title'),
          text: this._('Your country is changed.Page will be reloaded. text'),
        },
        redirect: { location: window.location.pathname },
      }
      AnswerMessage(meta, this)
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      const title = parsed.bonus_sum && parsed.bonus_sum[0]
      const data = {
        message: {
          title: this._('order create error header'),
          text: title ? title : this.non_field_errors ? this.non_field_errors[0] : '',
        },
      }
      AnswerMessage(data, this)
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>
