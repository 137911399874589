import { createStore as createCartStore } from '@store/cart'
import { createStore as createComparisonStore } from '@store/comparison'
import { createStore as createLikesStore } from '@store/like'
import { createStore as createRecipeStore } from '@store/recipe'
import { createStore as createSearchStore } from '@store/search'
import { createStore as createStaffStore } from '@store/staff'
import { createStore as createBrandStore } from '@store/brands'
import { createStore as createCountryStore } from '@store/country'
import { createStore as createTripStore } from '@store/trip'

export function createStore(parameters) {
  return {
    modules: {
      cart: createCartStore(parameters),
      comparison: createComparisonStore(parameters),
      like: createLikesStore(parameters),
      recipe: createRecipeStore(parameters),
      search: createSearchStore(parameters),
      staff: createStaffStore(parameters),
      brand: createBrandStore(parameters),
      country: createCountryStore(parameters),
      trip: createTripStore(parameters),
    },
  }
}

export function createStoreObject({ Store, Vue }) {
  return new Store(createStore({ Store, Vue }))
}
