import { tripRetrieveResource } from '@api/vendor.service'
export function createStore(parameters) {
  return {
    namespaced: true,
    state: {
      trip: {},
    },
    actions: {
      async getTrip({ commit }, payload) {
        const { data } = (await tripRetrieveResource.execute({ label: payload }))
        commit('SET_ITEM', data.props)
      },
    },
    mutations: {
      SET_ITEM(state, payload) {
        state.trip = payload
      },
    },
  }
}
