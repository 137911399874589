<template lang="pug">
  include /mixins
  div
    //- product-base-view(
    //-   v-for='(item, idx) in relations',
    //-   v-if='relations.length && !item.parameters.select_gift'
    //-   :product='item',
    //-   :set='product'
    //-   :parent='product.id'
    //-   :key='idx'
    //-   :load='load',
    //-   :is-sale='true'
    //-   :price='item.price'
    //-   :group_id='group_id'
    //-   :relations='relations'
    //- )
    //-   template(#sum='{ price }')
    //-     +b.ds-caption--size_sm.--semibold.--appearance_right.--color_accent.P(
    //-       v-html='getSplittedHtml(price)'
    //-     )
    //-   template(#price='{ price }')
    //-     +b.ds-caption--size_xs.--semibold.--appearance_right.P {{ _('Бесплатно') }}
    //-   template(#desktop-price='{ price }')
    //-     +b.ds-caption--size_xs.--semibold.--appearance_right.P {{ _('Бесплатно') }}
    //-   template(#price-tips='')
    //-   template(#total='')
    //-     +b.ds-caption--size_sm.--semibold.--appearance_right.--color_accent.P {{ _('Подарок') }}
    product-gifts-view(
      v-bind='giftsInfo'
    )
      //- v-if='relations.length'
    template(
      v-if='!product.parameters.is_sale_applied',
    )
      +b.sale-descriptor(
        v-for='val in product.parameters.campaigns',
        v-if='$options.DESCRIPTORS.includes(val.descriptor_type.value)'
      )
        +e.title(v-html='val.message[lang]')
        +e.link.A.tt-link--styling_default(
          :href='val.url'
        ) {{ _('Подробнее') }}
</template>
<script>

import { mapState } from 'vuex'

import ProductBaseView from './Base'
import ProductGiftsView from './Gifts'

const DESCRIPTORS = [
  'price_from_quantity',
  'discount_from_quantity',
  'discount_from_summ',
]

export default {
  name: 'ProductSaleView',
  components: {
    ProductBaseView,
    ProductGiftsView,
  },
  props: {
    product: {
      default: () => { },
    },
    load: {},
    relations: {
      default: () => [],
    },
    price: {
      default: '0.00',
    },
    group_id: {},
  },
  DESCRIPTORS,
  data() {
    return {
      currency: window.currency,
      lang: window.language,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart',
    ]),
    giftsInfo() {
      const { campaign_id } = this.product.parameters
      const id = `complect_${campaign_id}`
      return {
        set: this.cart.gifts_groups[id],
        id,
      }
      return {
        set: [{
          relations: this.relations,
          base: this.product,
        }],
        id: '_',
      }
    },
  },
}
</script>
