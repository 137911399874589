<template lang="pug">
include /mixins
+b.g-row
  +b.g-cell.g-cols--2-xl.is-visible-xl
  +b.g-cell.g-cols--12.--10-xl
    modal-trigger(
      name='SetView',
      v-if='firstSet && firstSet.base'
      :set='set',
      :campaign="campaignInfo"
      url='Cart/SetView',
      :classes='["cart-modal"]'
    )
      template(#default='{ open }')
        +b.set-cart-view.product_in_cart(
          @click.prevent='openGiftVariants'
        )
          +b.tt-new-sale-view
            +e.content
              +e.img-wrap
                +e.img.IMG(src!='/static/img/cart_gift.svg')
              +e.title.P(
                v-if='firstSet && firstSet.base'
                
              ) {{ _('Вам доступно') }} {{ firstSet.base.parameters.gift_amount }} {{ $tc('товар', firstSet.base.parameters.gift_amount) }} {{ _('в подарок.') }}
            +e.arrow.mdi.mdi-chevron-down
          +e.error(
            v-if='maxCount < l'
          )
            +b.ds-caption--size_xs.--regular.--color_red.P(
            ) {{ _('Товаров в подарок не хватает.') }}
            div(style='display: inline-flex; margin-top: 4px' @click.stop='')
              +b.d-control-static-input--appearance_inline.TAG(
                :tag='"input"'
                :input-label='_("Не участвую")'
                :key='`key_without_special_offer`'
                v-model='val'
                :ref="'limited_gifts_'+id"
                :id="'limited_gifts_'+id"
                :name="'limited_gifts_'+id"
                type='checkbox'
                :value='true'
                @change='clearValidation'
                @click.stop=''
              )
              label.input-group__input(
                :for="'limited_gifts_'+id"
                @click.stop=''
              )
                +b.ds-caption--size_xs.SPAN(@click.stop='') {{ _("Я согласен(на) на меньшее количество акционных товаров") }}
//- +b.set-cart-view.card-product-inner.product_in_cart(v-if='firstSet && firstSet.base')
//-   +e.imgs
//-     row(appearance='spaced')
//-       template(
//-         v-for='(item, index) in setPreviews'
//-       )
//-         cell(cols='3')
//-           +e.img
//-             +b.tt-picture--size_auto.--size_auto-h.product__img--mini.PICTURE.relative
//-               source(
//-                 type="image/webp"
//-                 :srcset="item.element.props.img && item.element.props.img.catalog_preview_webp"
//-               )
//-               +e.body.IMG(
//-                 v-if='item.element.props.img && item.element.props.img.catalog_preview'
//-                 :src='item.element.props.img && item.element.props.img.catalog_preview',
//-                 :alt='item.element.props.title',
//-               )
//-               +e.body.IMG(
//-                 v-else
//-                 src='https://via.placeholder.com/60x60',
//-               )
//-   +e.content(v-if='set && set.length && firstSet && firstSet.base')
//-     row(
//-       justify='between'
//-       align='center'
//-       appearance='nowrap'
//-     )
//-       cell(
//-         cols=''
//-       )
//-         +b.ds-caption--size_sm.--bold.--color_accent.P {{ _('Подарок') }}
//-       cell(
//-         cols=''
//-       )
//-         counter(
//-           :amount='firstSet.base.parameters.gift_amount',
//-           @change='$emit("updateCart", { info: $event, campaign: campaignInfo, set })'
//-           :max-count='maxCount'
//-           :show-error='l && maxCount < l'
//-           :disable='true'
//-         )

</template>

<script>
import { mapState, mapActions } from 'vuex'

import GiftChangeVariants from '@app/Cart/GiftChangeVariants'

const SET_PREVIEWS_COUNT = 8

export default {
  name: 'CartSetGiftPreview',
  props: ['set', 'id'],
  data() {
    return {
      currency: window.currency,
      val: false,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart', 'cartIsLoad',
    ]),
    campaignInfo() {
      const [, id, number] = this.id.split('_')
      if (this.cart.parameters.complects_info && this.cart.parameters.complects_info.length === 0) {
        return this.cart.parameters.complects_info
          .find(i => i.campaign_id === Number(id) && i.number_complect === Number(number))
      }
      const c = this.cart.groups.find(e => e.base.element.props.campaign && e.base.element.props.campaign.id == id )
      if (c) {
        return c.base.element.props.campaign
      }
      return []
    },
    campaign() {
      const [, id, number] = this.id.split('_')
      console.log(id);
      let c = Object.keys(this.cart.gifts_groups).map(key => {
        const r = this.cart.gifts_groups[key].find(e => e.base.element.props.campaign && e.base.element.props.campaign.id == id )
        if (r) return r
      })
      if (!c || c.length && !c[0]) {
        c = Object.keys(this.cart.gifts).map(key => {
          const r = this.cart.gifts[key].find(e => e.base.element.props.campaign && e.base.element.props.campaign.id == id )
          if (r) return r
        })
      }
      console.log(c);

      if (c && c.length && c[0]) {
        return c[0].base.element.props.campaign
      }
      return {}
    },
    maxCount() {
      return Math.min(...this.firstSet.relations.map(i => i.element.stock_info.stock_quantity_1c))
    },
    l() {
      const g = this.firstSet.relations.reduce((acc, obj) => {
        if (!obj.element.stock_info.is_valid) {
          acc.push([obj.quantity, obj.element.stock_info.stock_quantity_1c])
        }
        return acc 
      }, [])
      if (g.length) {
        return g[0][0]
      }
      return []
    },
    setPreviews() {
      if (!this.set) return []
      const [s] = JSON.parse(JSON.stringify(this.set))
      return s.relations.splice(0, SET_PREVIEWS_COUNT)
    },
    firstSet() {
      if (!this.set) return {}
      return this.set[0]
    },
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
      'rmFromValidation',
      'addFromValidation',
    ]),
    clearValidation(e) {
      this.val = e.target.checked
      if (this.val) {
        const ids = this.firstSet.relations.map(i => i.id)
        ids.forEach(id => this.rmFromValidation(id))
        return
      }
      this.addFromValidation()
    },
    openGiftVariants() {
      const pr = arg => new Promise((resolve, reject) => {
        if (arg) {
          resolve(arg)
          return
        }
        reject(new Error('Promise is rejected'))
      }).then(res => {
        this.getCart()
      })
      this.$modal.show(
        GiftChangeVariants,
        {
          id: this.set[0].base.id,
          promise: pr,
          count: this.set[0].base.parameters.gift_amount,
          set: this.firstSet,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
  },
}
</script>
