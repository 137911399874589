<template lang="pug">
  include /mixins
  +b.ds-panel
    +e.element--offset_full
      row
        cell(cols='12 6-md 9-xl')
          +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.--medium {{ _('Получатель:') }} &nbsp;
            +b.ds-caption.--medium.SPAN {{ pathGetter('props', 'last_name')(item) }} {{ pathGetter('props', 'first_name')(item) }}
              span(v-if='isUkraine && item.props.middle_name') &nbsp; {{ pathGetter('props', 'middle_name')(item) }}
          +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.tt-space_pt--2 {{ _('Страна:') }} &nbsp;
            +b.ds-caption.--regular.SPAN(
              v-if="item.props.country_available.props.parent"
            ) {{ pathGetter('props', 'country_available', 'props', 'parent', 'props', 'title' )(item) }}
            +b.ds-caption.--regular.SPAN(
              v-else
            ) {{ pathGetter('props', 'country_available', 'props', 'title' )(item) }}
          +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.tt-space_pt--2 {{ _('Город:') }} &nbsp;
            +b.ds-caption.--regular.SPAN(
              v-if="pathGetter('props', 'city', 'props', $options.lang == 'ru' ? 'description_ru' : 'description' )(item)"
            ) {{ pathGetter('props', 'city', 'props', $options.lang == 'ru' ? 'description_ru' : 'description' )(item) }}
            +b.ds-caption.--regular.SPAN(
              v-else
            ) {{ pathGetter('props', 'city', 'props', 'description' )(item) }}
            +b.ds-caption.--regular.--color_red.SPAN(
              v-if="item.props.city.props.is_not_available"
            ) &nbsp;({{ _('Город недоступен') }})
          +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.tt-space_pt--2 {{ _('Способ доставки:') }} &nbsp;
            +b.ds-caption.--regular.SPAN {{ pathGetter('props', 'shipping_service', 'props', 'title')(item) }}
          template(
            v-if='item.props.internal_warehouse'
          )
            +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.tt-space_pt--2 {{ _('Отделение:') }} &nbsp;
              +b.ds-caption.--regular.SPAN {{ pathGetter('props', 'internal_warehouse', 'props', 'address')(item) }}
          template(
            v-else-if='item.props.warehouse'
          )
            +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.tt-space_pt--2 {{ _('Отделение:') }} &nbsp;
              +b.ds-caption.--regular.SPAN(
                v-if="pathGetter('props', 'warehouse', 'props', $options.lang == 'ru' ? 'description_ru' : 'description')(item)"
              ) {{ pathGetter('props', 'warehouse', 'props', $options.lang == 'ru' ? 'description_ru' : 'description')(item) }}
              +b.ds-caption.--regular.SPAN(
                v-else
              ) {{ pathGetter('props', 'warehouse', 'props', 'description')(item) }}
              +b.ds-caption.--regular.--color_red.SPAN(
                v-if="item.props.warehouse.props.is_not_available"
              ) &nbsp;({{ _('отделение временно не работает') }})
          template(v-else)
            +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.tt-space_pt--2 {{ _('Адрес:') }} &nbsp;
              +b.ds-caption.--regular.SPAN.
                #[span(v-if='item.props.street_name') {{ pathGetter('props', 'street_name')(item) }}]   #[span(v-if='item.props.street') {{ pathGetter('props', 'street', 'props', 'streets_type')(item) }} {{ pathGetter('props', 'street', 'props', 'description')(item) }}], {{ _('буд.') }} {{ pathGetter('props', 'house')(item) }}#[span(v-if="item.props.building") , {{ _('корпус') }} {{ pathGetter('props', 'building')(item) }}]#[span(v-if="item.props.apartment") , {{ _('кв.') }} {{ pathGetter('props', 'apartment')(item) }} ] #[span(v-if="item.props.zip_code") , {{ _('Postal code') }} {{ pathGetter('props', 'zip_code')(item) }} ]
          +b.ds-caption--size_xs.--size_sm-xl.--color_grey.--bold.tt-space_pt--2 {{ _('Телефон:') }} &nbsp;
            +b.ds-caption.--regular.SPAN {{ pathGetter('props', 'phone')(item) }}
        cell(cols='12 6-md 3-xl')
          .is-visible-xl
            +b.tt-link--grey.--variant_start.ds-caption--size_xsm.--size_sm-xl.--light.ROUTER-LINK(
              :to='{ name: $options.ADDRESS_BOOK_EDIT_NAME, params: { id: item.id } }'
            ) #[span.mdi.mdi-pencil.tt-space_pr.tt-space_pr--2 ] {{ _('Редактировать') }}
            +b.tt-space_mt--3
            +b.tt-link--grey.--variant_start.ds-caption--size_xsm.--size_sm-xl.--color_semigrey.--light.A(
              @click.prevent='confirmAndDelete(item.id)'
            )  #[span.mdi.mdi-trash-can.tt-space_pr.tt-space_pr--2 ] {{ _('Удалить') }}
          .is-disable-xl
            +b.tt-button--grey-v2.--for-input.--redesign-h.tt-space_mt--4.ROUTER-LINK(
              :to='{ name: $options.ADDRESS_BOOK_EDIT_NAME, params: { id: item.id } }'
            )
              +e.title {{ _('Редактировать') }}
            +b.tt-button--grey-v2.--for-input.--redesign-h.tt-space_mt--4.A(
              @click.prevent='confirmAndDelete(item.id)'
            )
              +e.title {{ _('Удалить') }}
    +b.line-h--light
</template>
<script>

import {
  pathGetter,
  confirmWithPromise,
} from '@utils/helpers'

import {
  ADDRESS_BOOK_EDIT_NAME,
} from '@app/Staff/AddressBook/routes'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
  UKRAINE_CODE,
} from '@app/consts'

import {
  addressBookDelete,
} from '@api/staff/address.service'

const lang = window.language

export default {
  name: 'AddressBookCard',
  props: {
    item: Object,
  },
  ADDRESS_BOOK_EDIT_NAME,
  lang,
  data() {
    return {
    }
  },
  computed: {
    isUkraine() {
      return getCookie(COUNTRY_CODE_COOKIE_NAME) === UKRAINE_CODE
    },
  },
  methods: {
    pathGetter,
    async confirmAndDelete(id) {
      const question = this._('Удалить адрес? Это действие безвозвратно')
      try {
        await confirmWithPromise(question, this)
        await addressBookDelete.execute({ id })
      } finally {
        this.$emit('update:list')
      }
    },
  },
}
</script>
