<template lang="pug">
include /mixins
+b.ds-panel--space_2xl-xl
  +b.close-popup.mdi.mdi-close.BUTTON(
    @click.prevent='$emit("close")'
    v-if='canClose'
  )
  +e.element--offset_full
    +b.ds-caption.--size_lg.--size_xl-xl.--medium.--color_grey.P {{ _('Выберите желаемый язык сайта') }}
    +e.element--offset_bottom
      +b.tt-space_mt--5
        +b.g-row--space_lg.--appearance_spaced
          template(v-for='lang, key in langs')
            +b.g-cell.g-cols
              +b.knowledge-card__tag.js-tag
                +b.tt-link.ds-caption--size_sm.--color_grey.P(
                  @click.prevent='selectedLang = key; changeLang()'
                  :class='{ "tt-link--accent ": key == selectedLang }'
                ) {{ lang }}
    //- template(v-if='selectedLang')
    //-   +b.ds-caption.--size_2md.--size_lg-xl.--medium.--color_grey.P() {{ _('Какой из городов к вам ближе всего?') }}
    //-   +e.element--offset_bottom
    //-     +b.tt-space_mt--5
    //-       +b.g-row--space_lg.--appearance_spaced
    //-         template(v-for='item in country.props.activation_keys')
    //-           +b.g-cell.g-cols(v-if='hasLang(item.props.languages, selectedLang)')
    //-             +b.tt-link--styling_default.A(
    //-               :title='item.props.title'
    //-               @click.prevent='select(country)'
    //-             ) {{ item.props.title }}
    //-         template(v-for='child in country.props.children')
    //-           template(v-for='item in child.props.activation_keys')
    //-             +b.g-cell.g-cols(v-if='hasLang(item.props.languages, selectedLang)')
    //-               +b.tt-link--styling_default.A(
    //-                 :title='item.props.title'
    //-                 @click.prevent='select(child)'
    //-               ) {{ item.props.title }}
</template>

<script>

import {
  mapActions,
  mapState,
} from 'vuex'

import CountryKeysChoice from './CountryKeysChoice.vue'

export default {
  props: {
    country: {
      type: Object,
      required: true,
    },
    selectCountry: {
      type: Function,
      required: true,
    },
    canClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedLang: null,
    }
  },
  computed: {
    langs() {
      // const s = new Set()
      const s = {}
      this.country.props.activation_keys.forEach((item) => {
        item.props.languages.reverse().forEach((lang) => {
          s[lang.value] = lang.display
        })
      })
      this.country.props.children.forEach((child) => {
        child.props.activation_keys.forEach((item) => {
          item.props.languages.reverse().forEach((lang) => {
            s[lang.value] = lang.display
          })
        })
      })
      return s
    },
  },
  methods: {
    changeLang(country) {
      const pr = arg => new Promise((resolve, reject) => {
        if (arg) {
          resolve(arg)
          return
        }
        reject(new Error('Promise is rejected'))
      }).then(res => {
        this.select(res)
      })
      // if (this.country.props.children && this.country.props.children.length) {
      this.$modal.show(
        CountryKeysChoice,
        {
          country: this.country,
          promise: pr,
          selectedLang: this.selectedLang,
          select: this.select,
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
      return
      // }
    },
    hasLang(languages, lang) {
      return languages.some((item) => item.value === lang)
    },
    choiceLang(e) {
      this.changeLang(e)
    },
    select(country) {
      const e = JSON.parse(JSON.stringify(country))
      e.props.children = []
      this.selectCountry(e, () => this.$emit('close'))
    },
  },
}
</script>
