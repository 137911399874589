<template lang="pug">
include /mixins
+b.trip-monthly-distribution-wrap
  +b.trip-monthly-distribution
    +e.item(
      v-for='item, idx in list'
      :class='{ "done": item.status === PAST_MONTH_STATUS && item.balance >= 0, "fail": item.status === PAST_MONTH_STATUS && item.balance < 0,  "current": item.status === CURRENT_MONTH_STATUS }'
    )
      +e.icon.SVG(
        v-if='item.status == PAST_MONTH_STATUS && item.balance < 0'
        xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewbox='0 0 24 25' fill='none'
      )
        path(
          d='M11.1336 4C11.5185 3.33333 12.4808 3.33333 12.8657 4L21.5259 19C21.9108 19.6667 21.4297 20.5 20.6599 20.5H3.33938C2.56958 20.5 2.08846 19.6667 2.47336 19L11.1336 4Z'
          fill='#FF7A00'
        )
        path(
          d='M11.2476 14.5266C11.2476 14.8923 11.544 15.1897 11.9097 15.1897C12.2764 15.1897 12.5728 14.8933 12.5728 14.5275L12.5728 8.13816C12.5663 7.96677 12.4937 7.80455 12.3701 7.68558C12.2465 7.56662 12.0817 7.50016 11.9102 7.50016C11.7387 7.50016 11.5738 7.56662 11.4503 7.68558C11.3267 7.80455 11.254 7.96677 11.2476 8.13816L11.2476 14.5266Z'
          fill='white'
        )
        path(
          d='M11.2584 18.374C11.4241 18.5397 11.6488 18.6328 11.8831 18.6328C12.1174 18.6328 12.3421 18.5397 12.5078 18.374C12.6735 18.2084 12.7666 17.9836 12.7666 17.7493C12.7666 17.515 12.6735 17.2903 12.5078 17.1246C12.3421 16.9589 12.1174 16.8658 11.8831 16.8658C11.6488 16.8658 11.4241 16.9589 11.2584 17.1246C11.0927 17.2903 10.9996 17.515 10.9996 17.7493C10.9996 17.9836 11.0927 18.2084 11.2584 18.374Z'
          fill='white'
        )

      +e.icon.SVG(
        v-if='item.status === PAST_MONTH_STATUS && item.balance >= 0'
        xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewbox='0 0 24 25' fill='none'
      )
        path(
          d='M12 3.5C7.03125 3.5 3 7.53125 3 12.5C3 17.4688 7.03125 21.5 12 21.5C16.9688 21.5 21 17.4688 21 12.5C21 7.53125 16.9688 3.5 12 3.5Z'
          fill='#00C48C'
        )
        path(
          d='M15.9551 10.6816L11.1821 15.4546L8.00013 12.2726'
          stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'
        )

      +e.icon.SVG(
        v-if='item.status !== PAST_MONTH_STATUS'
        xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewbox='0 0 24 25' fill='none'
      )
        path(
          d='M12 3.5C7.03125 3.5 3 7.53125 3 12.5C3 17.4688 7.03125 21.5 12 21.5C16.9688 21.5 21 17.4688 21 12.5C21 7.53125 16.9688 3.5 12 3.5Z'
          stroke='var(--f-color)' stroke-width='1.5' stroke-miterlimit='10'
        )
        path(
          d='M12 6.5V13.25H16.5' stroke='var(--f-color)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')

      +e.date {{ item.month|dateTime }}.{{ item.year }}
      +e.text.P {{ idx+1 }} {{ _('мес') }}
</template>

<script>

import {
  mapState, mapActions,
} from 'vuex'

import pathOr from 'ramda/src/pathOr'

const CURRENT_MONTH_STATUS = 'current'
const PAST_MONTH_STATUS = 'past'

export default {
  props: {
    trip_label: {
      type: String,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      CURRENT_MONTH_STATUS,
      PAST_MONTH_STATUS,
    };
  },
  computed: {
    ...mapState('trip', ['trip']),
    list() {
      return pathOr({}, this.path, this.trip);
    },
  },
  // mounted() {
  //   this.getTrip(this.trip_label);
  // },
  methods: {
    ...mapActions('trip', ['getTrip']),
    getPercent(value, from) {
      return Math.round((value / from) * 100);
    },
  },
}

</script>
