<template lang="pug">
  include /mixins
  div(v-if='product.element && product.element.props.product')
    ui-loader(v-if='load || localLoad')
    +b.product_in_cart.relative
      slot(name='gift')
        +b.product-gift-info(
          v-if='isSale && product.parameters && product.parameters.select_gift'
          @click.prevent='openGiftVariants'
        )
          +b.product-gift
            +e.icon.icon-gift.SPAN
          +e.title.tt-link--styling_default.P {{ _('Выбрать другой подарок') }}
        +b.product-gift-info(
          v-if='isSale && product.parameters && !product.parameters.select_gift'
        )
          +b.product-gift
            +e.icon.icon-gift.SPAN
      +b.card-product-inner
        +b.g-row--align_center
          +b.g-cell.g-cols--3.--2-xl.relative
            +b.tt-picture--size_contain.product__img--mini.PICTURE.relative
              source(
                type="image/webp"
                :srcset="product.element.props.img && product.element.props.img.catalog_preview_webp"
              )
              +e.body.IMG(
                v-if='product.element.props.img && product.element.props.img.catalog_preview'
                :src='product.element.props.img && product.element.props.img.catalog_preview',
                :alt='product.element.props.title',
              )
              +e.body.IMG(
                v-else
                src='https://via.placeholder.com/60x60',
              )
            +b.cart-label-wrap
              +b.cart-label--static.is_sale(
                v-if='product.parameters && product.parameters.is_sale_applied'
              )
                +e.title.P {{ _('акция') }}
              template(
                v-for='rel in relations'
              )
                //- +b.cart-label--static.is_sale(
                //-   v-if='(rel && rel.parameters && rel.element && rel.element.type !== $options.PROMO_TYPE) || rel && rel.element && rel.element.props && rel.element.props.campaign && rel.element.props.campaign.id'
                //- )
                //-   +e.title.P {{ _('акция') }}
                +b.cart-label--static.is_sale(
                  v-if='(rel && rel.parameters && rel.element && rel.element.type == $options.PROMO_TYPE)'
                )
                  +e.title.P {{ _('промокод') }}
              +b.cart-label--static.is-discount(
                v-if='product.parameters && product.parameters.quality_type_id'
              )
                +e.title.P {{ _('Уценка') }}
          +b.g-cell.g-cols--9.--10-xl
            +b.g-row--appearance_column.--justify_between.full-h
              +b.g-cell.g-cols--12
                +b.g-row.--justify_between.full-h
                  +b.g-cell.g-cols--12.--6-xl
                    +b.g-row.--justify_between
                      +b.g-cell.g-cols
                        +b.ds-caption--size_xs(
                        ) {{ product.element.props.product.props.brand.props.title }}
                      +b.g-cell.g-cols.is-disable-xl
                        +b.ds-caption--size_xs.--appearance_right-md.P {{ product.element.props.vendor_code }}
                        
                    +b.ds-caption--size_sm.--size_sm-xl.--bold.A.tt-link--variant_start(
                      :class='{ "is-disabled": !product.element.props.show_in_catalog }'
                      :href='product.element.props.url'
                    ) {{ product.element.caption }}
                  +b.g-cell.g-cols--12.--6-md.is-visible-xl
                    +b.ds-caption--size_xs.--appearance_right-md.P {{ _('Артикул:') }} {{ product.element.props.vendor_code }}
                    slot(
                      name='desktop-price',
                      :price='product.price'
                    )
                      template(
                        v-if='saleInfo && saleInfo.parameters && saleInfo.parameters.calculated_price && $options.RED_PRICE_DESCRIPTORS.includes(saleInfo.element.type)'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(v-html='getSplittedHtml(product.price)')
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(v-html='getSplittedHtml(saleInfo.parameters.calculated_price)')
                      template(
                        v-else-if='product.parameters && product.parameters.quality_type_id'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(
                          v-html='getSplittedHtml(product.parameters.quality_type_default_price)'
                        )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(v-html='getSplittedHtml(product.price)')
                      template(
                        v-else-if='promo && promo.parameters && Number(promo.parameters.calculated_price)'
                      )
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(v-html='getSplittedHtml(product.price)')
                        +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(v-html='getSplittedHtml(promo.parameters.calculated_price)')
                      +b.ds-caption--size_sm.--semibold.--appearance_right.P(
                        v-else
                        v-html='getSplittedHtml(product.price)'
                      )
              +b.g-cell.g-cols--12.--6-xl
                
                +b.ds-caption--size_2xs.--medium.seller-product-name.P(
                  v-if='product.element.props.seller'
                ) {{ product.element.props.seller.props.title }}
                
              +b.g-cell.g-cols--12.--6-xl
              +b.g-cell.g-cols--12
                +b.g-row--justify_between.--align_center.--appearance_nowrap
                  +b.g-cell.g-cols
                    +b.g-row--appearance_nowrap.--align_center.--space_none-till-md
                      +b.g-cell.g-cols(
                        style='flex-shrink: 0'
                        v-if='product.element.props.weight.props.img || product.element.props.weight.props.color'
                      )
                        +b.product-weight-color-trigger--mini
                          +e.color.IMG(
                            v-if='product.element.props.weight.props.img'
                            :src='product.element.props.weight.props.img'
                          )
                          +e.color(
                            v-else
                            :style='{ "--color": product.element.props.weight.props.color }'
                          )
                      +b.g-cell.g-cols
                        +b.ds-caption--size_2xs.--color-relief.--medium.tt-space_pl--1.P(v-if='product.element.props.weight.props.color_number') {{ product.element.props.weight.props.color_number }} ({{ product.element.props.weight.props.name }})
                        +b.ds-caption--size_2xs.--color-relief.--medium.tt-space_pl--1.P(v-else) {{ product.element.props.weight.props.name }}
                    //- +b.ds-caption--size_xs.--medium.P(
                    //-   v-if='product.element.props.weight'
                    //- ) {{ product.element.props.weight.props.name }}
                    +b.ds-caption--size_2xs.--medium.--color_semigrey.P(v-if='product.parameters && product.parameters.quality_type_id') {{ product.parameters.quality_type_title }}
                  slot(name='sale')
                    +b.g-cell.g-cols(v-if='product.parameters && product.parameters.quality_type_id')
                      +b.ds-caption--size_sm.--medium.--color_red.--appearance_right.P {{ _('Скидка') }} #[+b.ds-caption--size_3md-xl.--color_red.SPAN -{{ product.parameters.quality_type_discount }}% ]
                    +b.g-cell.g-cols(v-if='saleInfo && saleInfo.element && $options.DISCOUNT_CONDITION_DESCRIPTORS.includes(saleInfo.element.type)')
                      +b.ds-caption--size_sm.--medium.--color_red.--appearance_right.P {{ _('Скидка') }} #[+b.ds-caption--size_3md-xl.--color_red.SPAN {{ saleInfo.parameters.title }} ]
                    template(
                      v-else-if='!isGift && (product.parameters && !product.parameters.is_sale_applied)',
                      v-for='val in product.parameters && product.parameters.campaigns'
                    )
                      +b.g-cell.g-cols--5(
                        v-if='$options.DESCRIPTORS.includes(val.descriptor_type.value)'
                      )
                        +b.ds-caption--size_2xs.--regular.--color_red(
                          v-html='val.message[lang]'
                        )
              //- +b.g-cell.g-cols--12.tt-space_mt--3.is-disable-xl
              //-   +b.g-row
              //-     +b.g-cell.g-cols--6
              //-       +b.ds-caption--size_xs.--medium.P(
              //-         v-if='product.element.props.weight'
              //-       ) {{ product.element.props.weight.props.name }}
              //-     +b.g-cell.g-cols--4
              //-       +b.ds-caption--size_xs {{ _('Артикул:') }} {{ product.element.props.vendor_code }}
              //-       slot(
              //-         name='price',
              //-         :price='product.price'
              //-       )
              //-         template(
              //-           v-if='saleInfo && saleInfo.parameters && saleInfo.parameters.calculated_price && $options.RED_PRICE_DESCRIPTORS.includes(saleInfo.element.type)'
              //-         )
              //-           +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(
              //-             v-html='getSplittedHtml(product.price)'
              //-           )
              //-           +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(
              //-             v-html='getSplittedHtml(saleInfo.parameters.calculated_price)'
              //-           )
              //-         template(
              //-           v-else-if='promo && promo.parameters && Number(promo.parameters.calculated_price)'
              //-         )
              //-           +b.ds-caption--size_sm.--semibold.--appearance_right.--appearance_line-througt.P(
              //-             v-html='getSplittedHtml(product.price)'
              //-           )
              //-           pre !
              //-           +b.ds-caption--size_sm.--semibold.--appearance_right.--color_red.P(
              //-             v-html='getSplittedHtml(promo.parameters.calculated_price)'
              //-           )
              //-         +b.ds-caption--size_sm.--semibold.--appearance_right.P(
              //-           v-else
              //-           v-html='getSplittedHtml(product.price)'
              //-         )
              //-     slot(
              //-       name='mob-sale',
              //-     )
              //- +b.g-cell.g-cols--12(
              //-   v-if='saleInfo && saleInfo.element && $options.DISCOUNT_CONDITION_DESCRIPTORS.includes(saleInfo.element.type)'
              //- )
              //-   +b.ds-caption--size_xsm.--medium.--color_red.--appearance_right.P.is-disable-xl {{ _('Скидка') }} #[+b.ds-caption--size_sm.--color_red.SPAN {{ saleInfo.parameters.title }} ]

        row(
          justify='between'
          align='center'
          appearance='nowrap'
        )
          +b.g-cell.g-cols--1.--2-xl.is-visible-xl
          +b.g-cell.g-cols.--6-xl
            slot(name='counter', :count='product.quantity')
              row(align='center' :appearance='["column-reverse-till-m2d", "nowrap", "spaced"]')
                cell
                  counter(
                    :amount='product.quantity',
                    :disable='isSale || disabled'
                    :max-count='product.element.props.stock'
                    :key='`${product.id}_${price}`'
                    @change='$emit("updateCart", $event)'
                    :variant='product.element.id'
                    :show-error='stockInfo && !stockInfo.is_valid && stockInfo.quantity > stockInfo.stock_quantity_1c'
                  )
                cell(v-if='bonus')
                  +b.cashback-percent
                    +e.value(v-html='getSplittedHtml(bonus)')
                    +e.icon.SVG(:title='_("cashback")' xmlns='http://www.w3.org/2000/svg', width='20', height='20', viewBox!='0 0 20 20', fill='none')
                      circle(cx='10' cy='10' r='10' fill='#FF7A00')
                      circle(cx='10' cy='10' r='8.5' fill='#FF7A00' stroke='white')
                      path(d='M10.5398 15C9.74432 15 9.00568 14.8816 8.32386 14.6448C7.65152 14.3989 7.06439 14.0528 6.5625 13.6066C6.07008 13.1603 5.68655 12.6321 5.41193 12.0219C5.13731 11.4117 5 10.7377 5 10C5 9.2623 5.13731 8.58834 5.41193 7.97814C5.68655 7.36794 6.07008 6.83971 6.5625 6.39344C7.06439 5.94718 7.65152 5.60565 8.32386 5.36885C9.00568 5.12295 9.74432 5 10.5398 5C11.5152 5 12.3769 5.16393 13.125 5.4918C13.8826 5.81967 14.5076 6.29326 15 6.91257L12.8835 8.72951C12.59 8.37432 12.2633 8.10109 11.9034 7.90984C11.553 7.70947 11.1553 7.60929 10.7102 7.60929C10.3598 7.60929 10.0426 7.66393 9.75852 7.77322C9.47443 7.88251 9.22822 8.04189 9.01989 8.25137C8.82102 8.46084 8.66477 8.71585 8.55114 9.01639C8.4375 9.30783 8.38068 9.6357 8.38068 10C8.38068 10.3643 8.4375 10.6967 8.55114 10.9973C8.66477 11.2887 8.82102 11.5392 9.01989 11.7486C9.22822 11.9581 9.47443 12.1175 9.75852 12.2268C10.0426 12.3361 10.3598 12.3907 10.7102 12.3907C11.1553 12.3907 11.553 12.2951 11.9034 12.1038C12.2633 11.9035 12.59 11.6257 12.8835 11.2705L15 13.0874C14.5076 13.6976 13.8826 14.1712 13.125 14.5082C12.3769 14.8361 11.5152 15 10.5398 15Z' fill='white')

            slot(name='stock')
              template(
                v-if='isLimited && (stockInfo && stockInfo.is_valid || !stockInfo)'
              )
                +b.ds-caption--size_2xs.--regular.--color_accent(
                  v-if='!product.element.props.stock'
                ) {{ _('Нет в наличии') }}
                +b.ds-caption--size_2xs.--regular.--color_accent(
                  v-else
                ) {{ _('Макс. кол-во товаров доступное к заказу -') }} &nbsp; {{  product.element.props.stock }} {{ _('шт.') }}
              template(
                v-if='stockInfo && !stockInfo.is_valid'
              )
                +b.ds-caption--size_2xs.--regular.--color_red(
                  v-if='!stockInfo.stock_quantity_1c'
                )
                  slot(name='notvalid:delete')
                    span {{ _('Товара нет в наличии, пожалуйста удалите его из корзины') }}
                +b.ds-caption--size_2xs.--regular.--color_red(
                  v-else-if='stockInfo.quantity > stockInfo.stock_quantity_1c'
                ) {{ _('Товара нет в необходимом Вам количестве, доступно ') }} {{ stockInfo.stock_quantity_1c }}
          +b.g-cell.g-cols.--1-xl
          +b.g-cell.g-cols.--3-xl
            slot(
              name='total',
            )
              row(
                justify='end'
                align='center'
              )
                cell.is-visible-md(
                  cols='12-xl'
                )
                  slot(
                    name='pricetips'
                  )
                    +b.ds-caption--size_sm.--light.--appearance_right.P  {{ _('Сумма:') }}
                cell(
                  cols='12-xl'
                )
                  slot(
                    name='sum',
                    :price='price'
                  )
                    template(
                      v-if='saleInfo && saleInfo.parameters && saleInfo.parameters.calculated_price'
                    )
                      +b.ds-caption--size_sm.--semibold.--color_red.--appearance_right.P(v-html='getSplittedHtml(price)')
                    
                    +b.ds-caption--size_sm.--semibold.--appearance_right.P(
                      v-html='getSplittedHtml(price)'
                      v-else
                    )
        template(v-if='!disabled')
          +b.remove-from-cart.SPAN.mdi.mdi-close(
            @click='$emit("removeFromCart")'
            :class='{ "is-active": stockInfo && !stockInfo.stock_quantity_1c }'
            v-if='!isSale'
          )

</template>
<script>

import {
  mapActions,
} from 'vuex'

import {
  changeGifts,
  cleanGifts,
  createGifts,
} from '@api/cart.service.js'

import GiftChangeVariants from '@app/Cart/GiftChangeVariants'

const RED_PRICE_DESCRIPTORS = [
  'sales.pricefromquantitydescriptor',
  'sales.pricefromtotalquantitydescriptor',

]
const DISCOUNT_CONDITION_DESCRIPTORS = [
  'sales.discountfromquantitydescriptor',
  'sales.discountfromsumdescriptor',
]
const DESCRIPTORS = [
  'discount_from_quantity',
  'discount_from_summ',
  'price_from_quantity',
]

const PROMO_TYPE = 'sales.promocode'

export default {
  name: 'product-base-view',
  DESCRIPTORS,
  RED_PRICE_DESCRIPTORS,
  DISCOUNT_CONDITION_DESCRIPTORS,
  PROMO_TYPE,
  props: {
    isGift: {},
    product: {
      default: () => { },
    },
    price: {
      default: '0.00',
    },
    isSale: {
      default: () => false,
    },
    load: {},
    disabled: {
      default: () => false,
    },
    parent: {},
    relations: {
      default: () => [],
    },
    group_id: {},
    set: {},
  },
  data() {
    return {
      currency: window.currency,
      lang: window.language,
      localLoad: false,
    }
  },
  computed: {
    promo() {
      return this.relations.find(e => e.element.type === PROMO_TYPE)
    },
    saleInfo() {
      if (this.relations.length) {
        const [rel] = this.relations
        return rel
      }
      return {}
    },
    isLimited() {
      return this.product.quantity >= this.product.element.props.stock
    },
    stockInfo() {
      return this.product.element.stock_info
    },
    bonus() {
      return this.product?.parameters?.bonus_amount
    },
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
    ]),
    openGiftVariants() {
      const pr = arg => new Promise((resolve, reject) => {
        if (arg) {
          resolve(arg)
          return
        }
        reject(new Error('Promise is rejected'))
      }).then(res => {
        this.getCart(res)
      })
      console.log(this.product);
      this.$modal.show(
        GiftChangeVariants,
        {
          id: this.parent,
          promise: pr,
          count: this.product.quantity,
          group_id: this.group_id,
          set: {
            id: this.group_id,
            base: this.set,
            relations: this.relations,
          },
        }, {
          height: 'auto',
          classes: ['cart-modal'],
          adaptive: true,
          scrollable: true,
        }
      )
    },
    async changeGift(data) {
      await cleanGifts.execute({}, { group_id: this.group_id })
      try {
        this.localLoad = true
        await createGifts.execute({}, data)
        await this.getCart()
        this.localLoad = false
      } catch (e) {
        this.localLoad = false
      }
    },
  },
}
</script>
