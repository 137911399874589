<template lang="pug">
include /mixins
div
  .is-visible-xl
    ui-accordion(
      :init='false',
      :is-calculate-height='false'
    )
      template(#default='{ activeState, toggleActive, collapse,  style: { icon, myHeight }, isDesktop, ...other }')
        ui-click-outside(
          :do='collapse'
        )
          +b.switcher-with-dropdown
            +e.current(
              @click.prevent='countriesList.length > 1 ? toggleActive() : () => ({})'
              v-if='selectedCountry && selectedCountry.props'
            )
              +e.img-wrap
                +e.img.IMG(
                  :src='selectedCountry.props.parent ? selectedCountry.props.parent.props.icon : selectedCountry.props.icon'
                  :alt='selectedCountry.props.parent ? selectedCountry.props.parent.props.title : selectedCountry.props.title'
                )
              +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN {{ selectedCountry.props.parent ? selectedCountry.props.parent.props.title : selectedCountry.props.title }}
              +e.icon.mdi.I(
                v-if='countriesList.length > 1'
                :class='icon'
              )
            transition(name='slidedown')
              +e.options(
                v-show="activeState"
                :class='classname'
              )
                +e.option(
                  v-for='country in countriesList'
                  @click='selectCountry(country, toggleActive)'
                  v-if='country !== selectedCountry || country.props && country.props.children && country.props.children.length > 0'
                )
                  //- template(v-if='!checkHostAndIso(country)')
                  +e.img-wrap
                    +e.img.IMG(
                      :src='country.props.parent ? country.props.parent.props.icon : country.props.icon '
                    )
                  +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN(
                  ) {{ country.props.parent ? country.props.parent.props.title : country.props.title }}
  .is-disable-xl
    +b.native-changer-select-wrapper(v-if='selectedCountry && selectedCountry.props')
      +b.native-changer-select--with-img.SELECT(
        :class='classname'
        @change='select'
        v-model='selectedCountry'
        :style='{ "--icon": `url("${selectedCountry && selectedCountry.props && selectedCountry.props.icon}")` }'
      )
        +e.option--with-img.OPTION(
          v-for='country in countriesList'
          :key='country.props.title'
          :value='country'
          :style='{ "--icon": `url("${country.props.icon}")` }'
        )
          //- +e.flag.SPAN {{ $options.FLAG_MAP[country.props.iso_code] }}
          +e.link.ds-caption--size_sm.--appearance_uppercase.SPAN(
          )  {{ country.props.parent ? country.props.parent.props.title : country.props.title }}
      ui-loader(v-if='load')
</template>

<script>

import {
  countriesListResource,
  countryChangeResource,
  LangChangeResource,
} from '@api/vendor.service'

import {
  mapActions,
  mapState,
} from 'vuex'

import {
  getCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'

import {
  removeLanguage,
  prefixLanguage,
} from '@utils/urls'

import CountryLangChoice from '@app/UiElements/Header/CountryLangChoice.vue'

const host = 'totispharma.com'
const isoExclude = '203'

const FLAG_MAP = {
  703: '🇸🇰',
  804: '🇺🇦',
  616: '🇵🇱',
  498: '🇲🇩',
  203: '🇨🇿',
  860: '🇺🇿',
}

export default {
  name: 'CountrySwitcher',
  props: ['classname'],
  countriesListResource,
  countryChangeResource,
  FLAG_MAP,
  data() {
    return {
      iso_code: getCookie(COUNTRY_CODE_COOKIE_NAME),
      // selectedCountry: {},
      list: [],
      load: false,
    }
  },
  computed: {
    ...mapState('country', [
      // 'selectedCountry',
      'countriesList',
      'originCountriesList',
    ]),
    selectedCountry: {
      get() {
        return this.$store.state.country.selectedCountry
      },
      set(value) {
        this.saveCountry(value)
      },
    },
  },
  methods: {
    ...mapActions('country', [
      'saveCountry',
      'getCountries',
      'changeCountry',
    ]),
    checkHostAndIso(country) {
      return country.props.iso_code === isoExclude && window.location.host === host && window.isSuperUser !== 'True'
    },
    async selectCountry(country, action) {
      if (!this.selectedCountry) return
      if (country.props.children && country.props.children.length) {
        this.$modal.show(
          CountryLangChoice,
          {
            country,
            selectCountry: this.selectCountry,
          }, {
            height: 'auto',
            classes: ['cart-modal'],
            adaptive: true,
            scrollable: true,
          }
        )
        return
      }
      this.saveCountry(country)
      this.changeCountry({
        action,
      })
    },
    select() {
      this.load = true
      this.selectCountry(this.selectedCountry, () => {})
    },
  },
}
</script>
