<template lang="pug">
  include /mixins
  tag(
    :tag="info && info.fromOrderPage ? 'div' : 'user-address-book'"
  )
    div
      .is-disable-xl(
        v-if='!(info && info.fromOrderPage)'
      )
        +b.ds-panel--space-xl(
        )
          +e.element--offset_full
            +b.tt-link--styling_default.ROUTER-LINK(
              :to='{ name: $options.ADDRESS_BOOK_LIST_NAME }'
            )
              +e.icon.mdi.mdi-chevron-left
              +e.title.SPAN  {{  _('Назад') }}
      div(
        :class='{"bordered bordered--light": !(info && info.fromOrderPage)}'
      )
        +b.ds-caption--size_lg.--medium.--appearance_center-xl.H2.main-title.tt-space_mb--5(
          v-if='info && info.fromOrderPage'
        ) {{ _('Добавить адрес') }}
        +b.VALIDATION-OBSERVER(
          ref="validator",
          v-slot="{ valid }",
          tag="form",
        )
          //- ui-loader(v-if='isLoad')
          +b.ds-caption--size_xsm.--size_sm-xl.--color_grey.--medium.P.tt-space_mt--6(
            v-if='!(info && info.fromOrderPage)'
          ) {{ id ?  _('Редактировать адрес') : _('Добавить адрес')  }}
          //- country-input(
          //-   v-model='form.country_available',
          //-   rules='required'
          //-   :has-init='!!!id'
          //-   v-if='!(info && info.fromOrderPage)'
          //- )
          delivery(
            @update='simulate($event)'
            :initial='form'
            :has-init='true'
            :is_not_available='false'
            :for_order='info && info.fromOrderPage'
            :country='form.country_available && form.country_available.props && form.country_available.props.iso_code'
          )
          template(v-if='form.shipping_service && form.shipping_service.props.conf_fields.filter(e => e.props.recipient_info).length')
            +b.ds-caption--size_xsm.--size_sm-xl.--color_grey.--medium.P.tt-space_mt--6 {{ _('Данные получателя') }}
          +b.g-row
            template(v-if='form.shipping_service && form.shipping_service.props.conf_fields.filter(e => e.props.recipient_info).length')
              //- +b.g-cell.g-cols--12.tt-space_mt--6(
              //-   v-for='{ rules, name, label, type, classname, mask, tag } in form_fields'
              //- )
              +b.g-cell.g-cols--12.tt-space_mt--6(
                v-for='field in form.shipping_service.props.conf_fields.filter(e => e.props.recipient_info)'
              )
                tag(
                  :tag='$options.TAG_MAP[field.props.type_input]',
                  v-bind="field", :ref='field.props.field.props.label', :name='field.props.field.props.label',
                  :rules="{ 'required': field.props.is_required, ...getValidation(field.props.field.props.validation) }",
                  :input-label='field.props.field.props.title', v-model='form[field.props.field.props.label]',
                )
              //- tag(
              //-   :tag='tag || "d-control-input"'
              //-   :rules="rules",
              //-   :type='type',
              //-   :input-label='label'
              //-   v-model='form[name]',
              //-   :ref='name'
              //-   @input='$emit("update", form)'
              //-   :name='name'
              //-   :class='classname',
              //- )
            +b.g-cell.g-cols--12.tt-space_mt--6.tt-space_mb--6
              +b.tt-button--redesign-h.BUTTON(
                @click.prevent='prepareData(valid)'
                type='submit'
              )
                +e.title.P {{ id ?  _(' сохранить') : _(' добавить адрес') }}
</template>
<script>
import { mapState } from 'vuex'

import Delivery from '@app/Order/Steps/Parts/Delivery'

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import {
  AnswerMessage,
} from '@utils/submit'

import {
  addressBookAdd,
  addressBookRetrieve,
  addressBookUpdate,
} from '@api/staff/address.service'

import {
  isEmpty,
} from '@aspectus/vue-utils'
import {
  COUNTRY_CODE_COOKIE_NAME,
} from '@app/consts'
import {
  getCookie,
} from '@utils/cookies'

import pathOr from 'ramda/src/pathOr'

import {
  ADDRESS_BOOK_LIST_NAME,
} from '@app/Staff/AddressBook/routes'

const TAG_MAP = {
  input: 'd-control-input',
  select: 'd-control-input',
  phone: 'phone-input',
}

export default {
  name: 'AddressBookAdd',
  props: {
    info: {},
  },
  addressBookAdd,
  ADDRESS_BOOK_LIST_NAME,
  TAG_MAP,
  mixins: [FormMixin],
  components: {
    Delivery,
  },
  data() {
    return {
      form_fields: [
        {
          name: 'first_name',
          cols: '12',
          label: this._('Имя'),
          rules: 'required',
        },
        {
          name: 'last_name',
          cols: '12',
          label: this._('Фамилия'),
          rules: 'required',
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
        },
      ],
      form: {
        country_available: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone: '',
        shipping_service: '',
        street_name: '',
        city: '',
        street: '',
        warehouse: '',
        internal_warehouse: '',
        house: '',
        apartment: '',
        building: '',
        country: '',
        zip_code: '',
      },
      id: null,
    }
  },
  computed: {
    ...mapState('staff', [
      'user',
    ]),
  },
  watch: {
    user: {
      handler() {
        console.log('USER', this.user);
        if (this.user && this.user.props && this.user.props.info && !this.id) {
          this.form.first_name = this.user.props.info.first_name
          this.form.last_name = this.user.props.info.last_name
          this.form.middle_name = this.user.props.info.middle_name
          this.form.phone = this.user.props.username
        }
      },
      immediate: true,
      eep: true,
    },
  },
  created() {
    this.id = this.$route.params.id || this.info?.id
    if (this.id) {
      this.getAddress()
    }
  },
  methods: {
    getValidation(arr) {
      return arr.reduce((acc, item) => {
        if (item.props.rule) {
          acc = { ...acc, ...item.props.rule }
        }
        return acc
      }, {})
    },
    async getAddress() {
      const { data: { props } } = (await addressBookRetrieve.execute({ id: this.id }))
      Object.keys(this.form).forEach(key => {
        this.form[key] = props[key]
      })
      if (props.warehouse && props.warehouse.props.is_not_available) {
        this.form.warehouse = ''
      }
    },
    async prepareData(valid) {
      await this.$refs.validator.validate()
      if (!valid) return
      const data = JSON.parse(JSON.stringify(this.form))
      data.city = pathOr(
        pathOr(
          pathOr(
            null,
            ['id'],
            this.form.city
          ),
          ['id'],
          this.form.warehouse
        ),
        ['id'],
        this.form.city
      )
      data.payment_gateway = pathOr(null, ['id'], this.form.payment_gateway)
      data.warehouse = pathOr(
        '',
        ['id'],
        this.form.warehouse
      )
      data.internal_warehouse = pathOr(null, ['id'], this.form.internal_warehouse)
      data.shipping_service = pathOr(null, ['id'], this.form.shipping_service)
      data.street = pathOr(null, ['id'], this.form.street)
      data.country_available = pathOr(getCookie(COUNTRY_CODE_COOKIE_NAME), ['props', 'iso_code'], this.form.country_available)

      // Object.keys(data).forEach(key => {
      //   if (isEmpty(data[key])) delete data[key]
      // })
      this.submit(valid, data)
    },
    send(data) {
      const action = this.id ? addressBookUpdate : addressBookAdd
      return action.execute({ id: this.id }, data)
        .then(res => {
          AnswerMessage(res.data, this)
          if (this.info?.promise) {
            this.info?.promise(res.data)
            this.$emit('close')
            return
          }
          this.$router.push({
            name: ADDRESS_BOOK_LIST_NAME,
          })
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
    simulate(event) {
      this.form = { ...this.form, ...event }
      this.$emit('update', this.form)
    },
  },
}
</script>
