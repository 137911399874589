const { language } = window

const heighter = () => {
  const block = document.getElementById('loyalty-main-block')
  if (!block) return
  if (991 < window.innerWidth) {
    block.style.height = 'var(--h)'
    return
  }
  const w = window.innerWidth
  const [g] = block.getElementsByClassName('loyalty-program__form-bg')
  if (!g) return
  const r = 320
  const e = (460 * (w / r)) + g.offsetHeight
  block.style.height = `${e}px`
}
export function checkLogo() {
  heighter()
  window.addEventListener('resize', heighter)

  const location = ['/', `/${language}/`, '/index.html']
  const TIMEOUT = 1
  location.forEach(el => {
    const logo = Array.from(document.getElementsByClassName('logo-wrapper'))
    if (window.location.pathname === el) {
      setTimeout(() => {
        logo.forEach(element => {
          element.classList.add('is-disabled')
        })
      }, TIMEOUT)
      return
    }
    logo.forEach(element => {
      element.classList.remove('is-disabled')
    })
  })
}

function scrollFunction() {
  const btnScroll = document.getElementById('scroll-top')
  if (!btnScroll) return
  const offset = 60
  if (document.body.scrollTop > offset ||
    document.documentElement.scrollTop > offset) {
    btnScroll.classList.add('is-active')
    return
  }
  btnScroll.classList.remove('is-active')
}

export const scrolly = () => {
  // Scroll to Up

  scrollFunction()

  window.addEventListener('scroll', scrollFunction, { passive: true })
}

export const fakeModalCloser = () => {
  const trigger = [...document.getElementsByClassName('js-modal-close')]
  const [p] = Array.from(document.getElementsByClassName('product-detail'))
  trigger.forEach(e => {
    e.addEventListener('click', () => {
      const { name } = e.dataset
      const checkbox = document.getElementById(`${name}`)
      checkbox.checked = false
      p.style.zIndex = 1
    })
  })
}

export const zIndexFixer = () => {
  const togglers = [...document.getElementsByClassName('toggler__input')]
  const [p] = Array.from(document.getElementsByClassName('product-detail'))
  togglers.forEach(e => {
    e.addEventListener('change', () => {
      // eslint-disable-next-line no-return-assign
      [...document.getElementsByClassName('product-sticky')].forEach(el => el.style.zIndex = 1)
      p.style.zIndex = 556
      const parent = e.closest('.product-sticky')
      parent.style.zIndex = 55
    })
  })
}

export const loyaltyTabs = () => {
  const tabs = [...document.getElementsByClassName('js-loyalty-tab')]
  const contents = [...document.getElementsByClassName('js-loyalty-content')]
  const dots = [...document.getElementsByClassName('js-loyalty-dot')]
  const forChange = [dots, contents]
  const active = 'is-active'
  tabs.forEach((e, i) => {
    e.addEventListener('click', () => {
      tabs.forEach(el => el.classList.remove(active))
      e.classList.add(active)
      forChange.forEach(el => {
        el.forEach(item => item.classList.remove(active))
        el[i].classList.add(active)
      })
    })
  })
}
