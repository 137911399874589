<template lang="pug">
  include /mixins
  +b.ds-panel--space_2xl.relative
    +b.close-popup.mdi.mdi-close.BUTTON(
      @click.prevent='$emit("close")'
    )
    +e.element--offset_full
      +e.element--offset_vertical(v-if='hasChoice')
        +b.ds-caption--size_lg.--medium.--appearance_center-xl.H2.main-title.tt-space_mb--5 {{ _('Выбор подарка') }}
        +b.ds-caption--size_sm.--medium.--appearance_center-xl.P {{ _('Вы можете изменить количество определенного подарка в общем количестве подарков.') }}
        +b.ds-caption--size_sm.--medium.--appearance_center-xl.P.tt-space_mb--3 {{ _('Общее количество подарков неизменно и составляет - ') }} {{ set.base.parameters.gift_amount }} {{ _('единиц') }}  
      +e.element--offset_vertical(v-else)
        +b.ds-caption--size_lg.--medium.--appearance_center-xl.H2.main-title.tt-space_mb--5 {{ _('Список подарков') }}
      ui-loader(v-if='load')
      +b.cart-wrapper
        template(
          v-for='item in getProductsList',
        )
          div(
          )
            product-base-view(
              :product='{ "element": item, "quantity": item.quantity }',
              :price='_("Подарок")',
              :disabled='true',
              :is-gift='true',
            )
              template(#sale='')
                counter(
                  :amount='item.quantity',
                  v-model='item.quantity'
                  :disable='!hasChoice || item.parameters && item.parameters.is_obligatory_gift'
                  :min-count='0'
                  :max-count='getMax(item)'
                  @input='setQuantity(item, $event)'
                )
              template(#mob-sale='')
                +b.g-cell.g-cols--12
                  counter(
                    :amount='item.quantity',
                    v-model='item.quantity'
                    :disable='!hasChoice || item.parameters && item.parameters.is_obligatory_gift'
                    :min-count='0'
                    :max-count='getMax(item)'
                  )
                  //- :max-count='product.element.props.stock'
                  //- @change='$emit("updateCart", $event)'
                  //- :show-error='stockInfo && !stockInfo.is_valid && stockInfo.quantity > stockInfo.stock_quantity_1c'
              template(#pricetips='')
                p
              template(#info='')
                p
              template(#sum='{ price }')
                p
              template(#counter='')
                p
              template(#price='{ price }')
                +b.ds-caption--size_sm.--semibold.--appearance_right.--color_accent.P
              template(#desktop-price='{ price }')
                +b.ds-caption--size_sm.--semibold.--appearance_right.--color_accent.P
              template(#price-tips='')
              template(#notvalid:delete='')
                span {{ _('Подарка нет в необходимом Вам количестве') }}
    ui-limit-pagination(
      :pagination='pagination'
      :need-add-btns='false'
      class='ds-panel--space_none-xl tt-space_mt tt-space_mt--5'
      @showAll='handlePaginate($event, changePagination, pagination)'
      @input='handlePaginate($event, pagination)'
    )
    +e.element--offset_full
      +b.ds-caption--size_sm.--medium.--color_red.P(v-if='!isValid && !cartIsInvalid') {{ _('Проверьте, чтобы сумма подарков была равна') }} {{ set.base.parameters.gift_amount }}
      //- +b.ds-caption--size_sm.--medium.--color_red.P(v-if='cartIsInvalid') {{ _('Проверьте наличие товаров') }}
      row(justify='end' v-if='hasChoice')
        cell
          +b.tt-button.BUTTON(
            :disabled='!isValid',
            @click.prevent='saveCurrentGifts'
          )
            +e.title.SPAN {{ _('Сохранить') }}

</template>
<script>

import { mapState } from 'vuex'

import {
  retrieveGiftsList,
  cleanGifts,
  createGifts,
} from '@api/cart.service.js'

// import ProductBaseView from '@app/Cart/Variants/Base'

export default {
  name: 'GiftChangeVariants',
  // components: {
  //   'product-base-view': ProductBaseView,
  // },
  props: ['id', 'promise', 'currentId', 'count', 'set'],
  data() {
    return {
      list: [],
      parameters: {
        id: this.id,
      },
      pagination: { offset: 0 },
      load: false,
    }
  },
  async mounted() {
    await this.getList(this.pagination)
  },
  computed: {
    ...mapState('cart', ['cart']),
    isValid() {
      if (!this.hasChoice) return true
      const count = this.totalCount
      return count === this.set.base.parameters.quantity_gift_select
    },
    cartIsInvalid() {
      return this.getProductsList.some(item => item.stock_info && !item.stock_info.is_valid)
    },
    hasChoice() {
      return !!this.set.base.parameters.quantity_gift_select
    },
    getProductsList() {
      const r = this.list.reduce((acc, obj) => {
        const info = this.getItemInfo(obj)
        // obj.quantity = info.quantity || 0
        obj.parameters = info.parameters
        if (info.parameters) {
          const equal = this.cart.groups.find(e => e.base.element.id === obj.id)
          let q = obj.props.stock
          if (equal) {
            q = obj.props.stock - equal.base.quantity
          }
          const manualCountCheck = obj.quantity > q
          obj.stock_info = {
            quantity: obj.quantity,
            stock_quantity_1c: q,
            is_valid: !manualCountCheck,
          }
          this.$set(obj, 'stock_info', obj.stock_info)
        }
        acc.push(obj)
        return acc
      }, [])
      return r
    },
    totalCount() {
      const count = this.getProductsList.reduce((acc, item) => {
        if (item.parameters && item.parameters.is_obligatory_gift) return acc
        acc += item.quantity
        return acc
      }, 0)
      return count
    },
  },
  methods: {
    handlePaginate({ offset, limit }, pagination) {
      if (this.loading) {
        return
      }
      this.getList({ limit, offset })
    },
    changeParameters() {
      // this.parameters = value
    },
    setQuantity(item, q) {
      const r = this.list.findIndex(e => e.id == item.id)
      this.list[r].quantity = q
    },
    getTotalCount() {
      const count = this.getProductsList.reduce((acc, item) => {
        if (item.parameters && item.parameters.is_obligatory_gift) return acc
        acc += item.quantity
        return acc
      }, 0)
      return count
    },
    async getList({ offset }) {
      this.load = true
      const { data: { items, pagination } } = (await retrieveGiftsList
        .execute({ id: this.id, offset }))
      this.pagination = pagination
      this.list = items.reduce((acc, obj) => {
        const info = this.getItemInfo(obj)
        obj.quantity = info.quantity || 0
        obj.parameters = info.parameters
        if (info.parameters) {
          const equal = this.cart.groups.find(e => e.base.element.id === obj.id)
          let q = obj.props.stock
          if (equal) {
            q = obj.props.stock - equal.base.quantity
          }
          const manualCountCheck = info.quantity > q
          obj.stock_info = {
            quantity: info.quantity,
            stock_quantity_1c: q,
            is_valid: !manualCountCheck,
          }
          this.$set(obj, 'stock_info', obj.stock_info)
        }
        acc.push(obj)
        return acc
      }, [])
      this.load = false
    },
    async selectCurrent(product_variant) {
      this.promise({ product_variant })
      this.$emit('close')
    },
    getMax(item) {
      const count = this.getTotalCount()
      const max = this.set.base.parameters.quantity_gift_select - count + item.quantity
      return max
    },
    getItemInfo(item) {
      const info = this.set.relations.find(el => el.element.id === item.id)
      if (info) return info
      return {}
    },
    async saveCurrentGifts() {
      await cleanGifts.execute({}, { group_id: this.set.id })
      const items = this.list.reduce((acc, item) => {
        acc.push({
          product_variant: item.id,
          quantity: item.quantity,
          parameters: {
            is_obligatory_gift: (item.parameters && item.parameters.is_obligatory_gift) || false,
          },
          group_id: this.set.id,
        })
        return acc
      }, []).filter(e => e.quantity)
      this.load = true
      await createGifts.execute({}, items)
      this.load = false
      this.promise(true)
      this.$emit('close')
    },
  },
}
</script>
