<template lang="pug">
include /mixins
div()
  slot
</template>

<script>

import {
  mapState, mapActions,
} from 'vuex'

import pathOr from 'ramda/src/pathOr'

export default {
  props: {
    trip_label: {
      type: String,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('trip', ['trip']),
    list() {
      return pathOr({}, this.path, this.trip);
    },
  },
  methods: {
    ...mapActions('trip', ['getTrip']),
  },
}

</script>
