<script>
import { getId } from '@utils/helpers'
export default {
  name: 'UiYoutubeView',
  props: {
    url: {},
  },
  computed: {
    preview() {
      return `https://img.youtube.com/vi/${this.getId(this.url)}/maxresdefault.jpg`
    },
  },
  methods: {
    getId,
    openPopupWithVideo() {
      const interval = 100
      setTimeout(() => {
        this.$modal.show({

          template: `
            <div class='ds-aspect-ratio ds-aspect-ratio--appearance_filled ds-aspect-ratio--ratio_16x9'>
              <iframe class='ds-aspect-ratio__body' :src="url" allowfullscreen></iframe>
              <a class="close-popup" @click.prevent='$emit("close")'> &times; </a>
            </div>
          `,
          props: ['url'],
        }, {
          url: `//www.youtube.com/embed/${this.getId(this.url)}?modestbranding=1;rel=0;autoplay=1&mute=0;iv_load_policy=3`,
        },
        {
          classes: 'videoPopup',
          height: 'auto',
          minWidth: 300,
          maxWidth: 370,
          adaptive: true,
          scrollable: true,
        })
      }, interval)
    },

  },
  render(h) {
    return this.$scopedSlots.default({
      play: () => this.openPopupWithVideo(),
      preview: this.preview,
      hasUrl: !!this.url,
    })
  },
}

</script>
