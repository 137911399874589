/* eslint-disable camelcase */
import {
  retrieveCartEntity,
  changeCartEntity,
  clearCartEntity,
  retrieveCartInfo,
  newChangeCartEntity,
  checkCartStock,
} from '@api/cart.service'

import { getPackagesList } from '@api/store.service'

import pathOr from 'ramda/src/pathOr'

const SET_CART = 'SET_CART'
const SET_CART_INFO = 'SET_CART_INFO'
const SET_CART_STOCK = 'SET_CART_STOCK'
const ADD_STOCK_DATA = 'ADD_STOCK_DATA'
const SET_PACKAGES = 'SET_PACKAGES'
const REMOVE_FROM_STOCK = 'REMOVE_FROM_STOCK'
const SET_LOAD = 'SET_LOAD'

const { isPreorder } = window
const cartParams = isPreorder ? { for_preorder: true } : {}

export function createStore() {
  return {
    namespaced: true,
    state: {
      cartIsLoad: false,
      cart: {
        groups: [],
        seller_ids: [],
        sets: [],
        gifts: [],
        gifts_groups: [],
        sales_groups: [],
      },
      coupon: 0,
      cart_info: {
        quantity: 0,
        total_price: 0,
      },
      stock: [],
      packages: [],
    },
    getters: {
      cartIsValid(state) {
        return state.stock.every(e => e.is_valid)
      },
    },
    actions: {
      async getCart({ commit, dispatch }, payload) {
        commit(SET_LOAD, true)
        return new Promise(async (resolve, reject) => {
          const { data } = (await retrieveCartEntity.execute(cartParams))
          // dispatch('getStockData')
          commit(SET_CART, { payload: data })
          commit(ADD_STOCK_DATA)
          commit(SET_LOAD, false)
          resolve()
        })
      },
      async getPackages({ commit }) {
        return new Promise(async (resolve, reject) => {
          const { data: { pagination: { total } } } = (await getPackagesList.execute())
          commit(SET_PACKAGES, { payload: total })
          resolve()
        })
      },
      async changeCart({ commit, dispatch }, { data, retrieve }) {
        return new Promise(async (resolve, reject) => {
          try {
            const res = (await changeCartEntity.execute(cartParams, data))
            commit(SET_CART, { payload: res.data })
            commit(ADD_STOCK_DATA)
            return resolve(res)
          } catch (e) {
            reject(e)
          }
        })
      },
      async clearCart({ commit, dispatch }, payload) {
        const { data } = (await clearCartEntity.execute())
        dispatch('getCart')
        // commit(SET_CART, { payload: data })
      },
      async getCartInfo({ commit }, payload) {
        const { data } = (await retrieveCartInfo.execute())
        commit(SET_CART_INFO, data)
      },
      async getStockData({ commit }) {
        return Promise.resolve(true)
        // return checkCartStock.execute()
        //   .then(data => {
        //     commit(SET_CART_STOCK, data)
        //     commit(ADD_STOCK_DATA)
        //   })
      },
      rmFromValidation({ commit }, payload) {
        commit(REMOVE_FROM_STOCK, payload)
      },
      addFromValidation({ commit }) {
        commit(ADD_STOCK_DATA)
      },
    },
    mutations: {
      [SET_LOAD](state, payload) {
        state.cartIsLoad = payload
      },
      [REMOVE_FROM_STOCK](state, payload) {
        state.stock = state.stock.filter(e => e.id !== payload)
        console.log(state.stock);
      },
      [SET_CART](state, payload) {
        // state.cart
        state.cart = payload.payload
        state.cart_info.quantity = state.cart.quantity
        const groupForRemove = []
        const gifts = {}
        const gifts_groups = {}
        const sales_groups = {}
        state.cart.sets = state.cart.groups.reduce((acc, obj, i) => {
          const { campaign_id, number_complect } = obj.base.parameters
          const { product } = obj.base.element.props
          if (campaign_id && number_complect) {
            if (product) {
              acc[`complect_${campaign_id}_${number_complect}`] = acc[`complect_${campaign_id}_${number_complect}`] || []
              acc[`complect_${campaign_id}_${number_complect}`].push(obj)
            } else {
              gifts[`complect_${campaign_id}_${number_complect}`] = gifts[`complect_${campaign_id}_${number_complect}`] || []
              gifts[`complect_${campaign_id}_${number_complect}`].push(obj)
            }
            groupForRemove.push(obj.id)
          }
          return acc
        }, {})
        state.cart.gifts = gifts
        state.cart.groups.reduce((acc, obj) => {
          const { campaign_id, number_complect } = obj.base.parameters
          const { product } = obj.base.element.props
          console.log('campaign_id', campaign_id);
          if (campaign_id && !number_complect && obj.relations.length && !product) {
            gifts_groups[`complect_${campaign_id}`] = gifts_groups[`complect_${campaign_id}`] || []
            gifts_groups[`complect_${campaign_id}`].push(obj)
            groupForRemove.push(obj.id)
          }
          if (campaign_id && !number_complect && product) {
            sales_groups[campaign_id] = sales_groups[campaign_id] || []
            sales_groups[campaign_id].push(obj)
            // groupForRemove.push(obj.id)
          }
          return acc
        }, {})
        state.cart.gifts_groups = gifts_groups
        state.cart.sales_groups = sales_groups
        console.log(state.cart.gifts_groups);
        state.cart.groups = state.cart.groups.filter(e => !groupForRemove.includes(e.id))
      },
      [SET_PACKAGES](state, payload) {
        state.hasPackages = payload.payload
      },
      [SET_CART_INFO](state, payload) {
        state.cart_info = payload
        window.cart_total = state.cart_info.total_price
      },
      [SET_CART_STOCK](state, payload) {
        state.stock = payload
      },
      [ADD_STOCK_DATA](state) {
        state.stock = []
        state.cart.groups.forEach(group => {
          let d = state.stock.find(e => e.id == group.base.element.id)
          const manualCountCheck = group.base.quantity > group.base.element.props.stock
          if (!d) {
            d = {
              id: group.base.id,
              quantity: group.base.quantity,
              stock_quantity_1c: group.base.element.props.stock,
              is_valid: !manualCountCheck,
            }
            state.stock.push(d)
          }
          this._vm.$set(group.base.element, 'stock_info', d)
          group.relations.forEach(relation => {
            let d = state.stock.find(e => e.id == relation.element.id)
            const manualCountCheck = relation.quantity > relation.element.props.stock
            if (!d) {
              d = {
                id: relation.id,
                quantity: relation.quantity,
                stock_quantity_1c: relation.element.props.stock,
                is_valid: !manualCountCheck,
              }
              state.stock.push(d)
            }
            this._vm.$set(relation.element, 'stock_info', d)
          })
        })
        const keys = ['gifts_groups', 'gifts']
        keys.forEach($key => {
          Object.keys(state.cart[$key]).forEach(key => {
            state.cart[$key][key].forEach(group => {
              group.relations.forEach(relation => {
                let d = state.stock.find(e => e.id == relation.element.id)
                const equal = state.cart.groups.find(e => e.base.element.id === relation.element.id)
                let q = relation.element.props.stock
                if (equal) {
                  q = relation.element.props.stock - equal.base.quantity
                }
                const manualCountCheck = relation.quantity > q
                if (!d) {
                  d = {
                    id: relation.id,
                    quantity: relation.quantity,
                    stock_quantity_1c: q,
                    is_valid: !manualCountCheck,
                  }
                  state.stock.push(d)
                }
                this._vm.$set(relation.element, 'stock_info', d)
              })
            })
          })
        })
        const setAndCampaignkeys = ['sets', 'sales_groups']
        setAndCampaignkeys.forEach($key => {
          Object.keys(state.cart[$key]).forEach(key => {
            state.cart[$key][key].forEach(group => {
              let d = state.stock.find(e => e.id == group.base.element.id)
              const manualCountCheck = group.base.quantity > group.base.element.props.stock
              if (!d) {
                d = {
                  id: group.base.element.id,
                  quantity: group.base.quantity,
                  stock_quantity_1c: group.base.element.props.stock,
                  is_valid: !manualCountCheck,
                }
                state.stock.push(d)
              }
            this._vm.$set(group.base.element, 'stock_info', d)
          })
        })
        })
      },
    },
  }
}
